import React from 'react'
import { useIntl } from 'react-intl'
import { MapTo } from '@adobe/aem-react-editable-components'
import messages from '@src/utils/messages'
import aemMessages from '@src/aem-content/messages'
import WebinarListing from '@sial/aem-cms-components/dist/WebinarListing/WebinarListing'

const WebinarListingAdapter: React.FC<any> = (props) => {
  const intl = useIntl()
  const upcoming = intl.formatMessage(aemMessages['WEBINAR_UPCOMING_TAB'])
  const onDemand = intl.formatMessage(aemMessages['WEBINAR_ON_DEMAND_TAB'])
  const session = intl.formatMessage(aemMessages['WEBINAR_SESSION_LABEL'])
  const presented = intl.formatMessage(aemMessages['WEBINAR_PRESENTED_LABEL'])
  const language = intl.formatMessage(messages['LANGUAGE'])
  const duration = intl.formatMessage(aemMessages['WEBINAR_DURATION_LABEL'])

  const noSearchResults = intl.formatMessage({
    id: 'NO_SEARCH_RESULTS',
    defaultMessage: 'No search results',
  })
  const changeFilterCriteria = intl.formatMessage({
    id: 'CHANGE_FILTER_CRITERIA',
    defaultMessage: 'Try changing or broadening your filter criteria.',
  })
  const dateRangeStart = intl.formatMessage({
    id: 'DATE_RANGE_START',
    defaultMessage: 'Date Range Start',
  })
  const dateRangeEnd = intl.formatMessage({
    id: 'DATE_RANGE_END',
    defaultMessage: 'Date Range End',
  })
  const uncheckAll = intl.formatMessage({
    id: 'FACET_UNCHECK_ALL',
    defaultMessage: 'Uncheck All',
  })

  const facetTopic = intl.formatMessage({
    id: 'FACET_TOPIC',
    defaultMessage: 'Topic',
  })
  const facetLanguages = intl.formatMessage(messages['LANGUAGES'])

  const appliedFilters = intl.formatMessage({
    id: 'APPLIED_FILTERS_HEADING',
    defaultMessage: 'Applied Filters:',
  })
  const learnMore = intl.formatMessage(messages['LEARN_MORE'])
  const selectDate = intl.formatMessage({
    id: 'SELECT_DATE',
    defaultMessage: 'Select Date',
  })

  const done = intl.formatMessage({ id: 'DONE', defaultMessage: 'Done' })
  const filterAndSort = intl.formatMessage(messages['FILTER_AND_SORT_HEADING'])
  const filters = intl.formatMessage({
    id: 'FILTERS',
    defaultMessage: 'Filters',
  })

  const selectAllFilters = intl.formatMessage(messages['SELECT_ALL'])
  const deselectAllFilters = intl.formatMessage(messages['DESELECT_ALL'])

  const invalidDate = intl.formatMessage({
    id: 'ENTER_DATE_INVALID',
    defaultMessage: 'Enter valid date',
  })
  const invalidStartDate = intl.formatMessage({
    id: 'START_DATE_INVALID',
    defaultMessage: 'Start Date should be before End Date',
  })
  const invalidEndDate = intl.formatMessage({
    id: 'END_DATE_INVALID',
    defaultMessage: 'End Date should be after Start Date',
  })
  const page = intl.formatMessage({
    id: 'PAGE',
    defaultMessage: 'Page',
  })
  const pagination = intl.formatMessage({
    id: 'WEBINAR_PAGINATION',
    defaultMessage:
      'Showing {pageStart} - {pageEnd} of {numberOfPages} Results',
  })

  const translations = {
    upcoming,
    onDemand,
    session,
    presented,
    language,
    duration,

    appliedFilters,
    changeFilterCriteria,
    dateRangeEnd,
    dateRangeStart,
    done,
    facetLanguages,
    facetTopic,
    filterAndSort,
    filters,
    invalidDate,
    invalidEndDate,
    invalidStartDate,
    learnMore,
    noSearchResults,
    selectDate,
    uncheckAll,
    selectAllFilters,
    deselectAllFilters,
    page,
    pagination,
  }

  return <WebinarListing {...props} translations={translations} />
}

export default MapTo('cms-commons/components/content/webinarlisting')(
  WebinarListingAdapter
)
