import React from 'react'
import {
  Page as AemPage,
  ComponentMapping,
  withModel,
} from '@adobe/aem-react-editable-components'
import { ModelManager, ModelClient } from '@adobe/aem-spa-page-model-manager'
import BackToTopButton from '@src/components/BackToTopButton'
import ProductModalEventListener from '@src/components/ProductModalEventListener'
import HalfGatedContent from './HalfGatedContent'
import { Components } from '../components'

export type PageProps = {
  aemHost: string
  locationPathname?: string
  cqPath: string
  cqChildren: any // not sure what this is yet, comes in usually as 'undefined'
  cqItems: {}
  cqItemsOrder: string[]
  pageFooter?: React.ReactNode
  isInEditor: boolean
  className?: string
}

export type CategoryState = {
  componentMapping: typeof ComponentMapping
}
class Page extends AemPage<PageProps, CategoryState> {
  private modelClient: ModelClient
  private modelManager: typeof ModelManager

  constructor(props: PageProps) {
    super(props)

    // Ensure a `fetch` does not take place client-side, as this data is provided serverSide
    if (typeof window !== 'undefined') {
      ModelClient.prototype.fetch = (): any => Promise.resolve({})
      ModelManager.getData = (): any => Promise.resolve({})
    }

    this.modelClient = new ModelClient(props.aemHost)

    this.modelManager = ModelManager
    this.modelManager.initializeAsync({ modelClient: this.modelClient })
  }

  componentWillUnmount() {
    this.modelClient.destroy()
  }

  render() {
    const { props } = this as {} as React.Component<PageProps>
    return (
      <div className={`aem-page ${props?.className}`}>
        {this.childComponents}
        {this.childPages}
        <Components.GlobalModals />
        <BackToTopButton />
        <ProductModalEventListener />
        <HalfGatedContent />
        {props.pageFooter}
      </div>
    )
  }
}

export default withModel(Page)
