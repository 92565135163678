// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type MetadataForFfCertificatesQueryVariables = Types.Exact<{
  input: Types.GetProductInput;
}>;


export type MetadataForFfCertificatesQuery = { __typename?: 'Query', getMetadataForFFCertificates?: { __typename?: 'FFCertificateProduct', productName?: string | null, productNameSuffix?: string | null, productNumber?: string | null, brand?: string | null, aliases?: Array<{ __typename?: 'FFMetadata', key?: string | null, value?: string | null, label?: string | null, sequence?: number | null } | null> | null, attributes?: Array<{ __typename?: 'FFMetadata', key?: string | null, value?: string | null, label?: string | null, sequence?: number | null } | null> | null, logo?: { __typename?: 'CatalogImage', altText?: string | null, smallUrl: string, mediumUrl: string, largeUrl: string } | null } | null };


export const MetadataForFfCertificatesDocument = gql`
    query metadataForFFCertificates($input: GetProductInput!) {
  getMetadataForFFCertificates(input: $input) {
    productName
    productNameSuffix
    productNumber
    brand
    aliases {
      key
      value
      label
      sequence
    }
    attributes {
      key
      value
      label
      sequence
    }
    logo {
      altText
      smallUrl
      mediumUrl
      largeUrl
    }
  }
}
    `;
export type MetadataForFfCertificatesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MetadataForFfCertificatesQuery, MetadataForFfCertificatesQueryVariables>, 'query'> & ({ variables: MetadataForFfCertificatesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MetadataForFfCertificatesComponent = (props: MetadataForFfCertificatesComponentProps) => (
      <ApolloReactComponents.Query<MetadataForFfCertificatesQuery, MetadataForFfCertificatesQueryVariables> query={MetadataForFfCertificatesDocument} {...props} />
    );
    

/**
 * __useMetadataForFfCertificatesQuery__
 *
 * To run a query within a React component, call `useMetadataForFfCertificatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetadataForFfCertificatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetadataForFfCertificatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMetadataForFfCertificatesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MetadataForFfCertificatesQuery, MetadataForFfCertificatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MetadataForFfCertificatesQuery, MetadataForFfCertificatesQueryVariables>(MetadataForFfCertificatesDocument, options);
      }
export function useMetadataForFfCertificatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MetadataForFfCertificatesQuery, MetadataForFfCertificatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MetadataForFfCertificatesQuery, MetadataForFfCertificatesQueryVariables>(MetadataForFfCertificatesDocument, options);
        }
export type MetadataForFfCertificatesQueryHookResult = ReturnType<typeof useMetadataForFfCertificatesQuery>;
export type MetadataForFfCertificatesLazyQueryHookResult = ReturnType<typeof useMetadataForFfCertificatesLazyQuery>;
export type MetadataForFfCertificatesQueryResult = ApolloReactCommon.QueryResult<MetadataForFfCertificatesQuery, MetadataForFfCertificatesQueryVariables>;