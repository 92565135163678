import React from 'react'
import { useIntl } from 'react-intl'
import { MapTo } from '@adobe/aem-react-editable-components'
import aemMessages from '@src/aem-content/messages'
import UpcomingEventsGrid from '@sial/aem-cms-components/dist/UpcomingEventsGrid/UpcomingEventsGrid'

const UpcomingEventsGridAdapter: React.FC<any> = (props) => {
  const intl = useIntl()
  const moreEvents = intl.formatMessage(aemMessages['MORE_EVENTS_LINK_TITLE'])
  const upcomingEvents = intl.formatMessage(aemMessages['UPCOMING_EVENTS'])

  const translations = {
    moreEvents,
    upcomingEvents,
  }

  return <UpcomingEventsGrid {...props} translations={translations} />
}

export default MapTo('cms-commons/components/content/upcomingeventsgrid')(
  UpcomingEventsGridAdapter
)
