import React from 'react'
import { Theme, Link as MuiLink } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from '@src/components/Link'
import { LinkProps } from 'next/link'
import DOMPurify from 'isomorphic-dompurify'
import parse from 'html-react-parser'
import { sendNavigationInteractionEvent } from '@src/utils/analytics'

const useStyles = makeStyles((theme: Theme) => ({
  imageHeight: {
    position: 'relative',
    background: 'transparent',
    width: '35px',
    height: '35px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'end',
    [theme.breakpoints.down('xs')]: {
      width: '32px',
      height: '32px',
      marginBottom: theme.spacing(4),
    },
  },
  imgResponsive: {
    width: '100%',
    objectFit: 'cover',
  },
}))

export interface UserEngageCardProps {
  cardImageAltTxt: string
  cardImageReference: string
  cardImageTitle: string
  url: string | LinkProps
  title: string
  untransformedUrl: string
}

export const UserEngageCard: React.FC<UserEngageCardProps> = ({
  cardImageAltTxt,
  cardImageReference,
  cardImageTitle,
  title,
  untransformedUrl,
}) => {
  const _url = untransformedUrl ?? ''
  const classes = useStyles()
  const getLabel = (label) => {
    const sanitizedContent = DOMPurify.sanitize(label)
    return parse(sanitizedContent)
  }

  const handleCardClick = () =>
    sendNavigationInteractionEvent(
      {
        action: 'shortcut click',
        detail: title.toLowerCase() || undefined,
        section: 'shortcuts',
        component: 'body',
        elementType: 'icon',
        elementText: title.toLowerCase() || undefined,
        linkUrl: untransformedUrl || undefined,
      },
      {
        eventAction: 'home page',
        eventCategory: 'deja new',
        eventLabel: title.toLowerCase() || undefined,
        eventInteractionType: 0,
      }
    )

  return (
    <>
      {_url ? (
        <Link {...(typeof _url === 'string' ? { href: _url } : _url)} passHref>
          <MuiLink color="primary" onClick={handleCardClick}>
            <div>
              <div className={classes.imageHeight}>
                <img
                  src={cardImageReference}
                  className={classes.imgResponsive}
                  alt={cardImageAltTxt}
                  title={cardImageTitle}
                />
              </div>
              <span>{getLabel(title)}</span>
            </div>
          </MuiLink>
        </Link>
      ) : (
        <MuiLink color="primary">
          <div>
            <div className={classes.imageHeight}>
              <img
                src={cardImageReference}
                className={classes.imgResponsive}
                alt={cardImageAltTxt}
                title={cardImageTitle}
              />
            </div>
            <span>{title}</span>
          </div>
        </MuiLink>
      )}
    </>
  )
}
