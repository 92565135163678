import { useRouter } from '@src/routes'
import { LocalizedSingletonRouter } from '@utils/useLocalizedRouter'
import { Sort } from '@src/types/graphql-types'
import { SelectedFacetState } from './types'
import { ParsedUrlQuery } from 'querystring'

export enum QueryParamKeys {
  Page = 'page',
  PerPage = 'offset',
  Sort = 'sort',
  SelectedFacets = 'facets',
  SearchTerm = 'term',
  SearchedFor = 'searched',
}

export const DEFAULT_SEARCH_VALUES = {
  [QueryParamKeys.Page]: 1,
  [QueryParamKeys.PerPage]: 30,
  [QueryParamKeys.Sort]: Sort.Relevance,
  [QueryParamKeys.SelectedFacets]: {},
  [QueryParamKeys.SearchTerm]: '',
  [QueryParamKeys.SearchedFor]: '',
}

const DELIMITERS = {
  FACETS: ',',
  OPTION_SETS: ':',
  OPTIONS: '*',
  URL_PREFIXES: '++', // Can't use '/' since some values contain markup
  VALUE_PREFIXES: '++',
}

interface ProductSearchQueryParams {
  term: string
  searched: string
  page: number
  perPage: number
  sort: Sort
  facets: SelectedFacetState
}

interface QueryState {
  searchTerm: string
  searchedFor: string
  page: number
  perPage: number
  sort: Sort
  selectedFacets: SelectedFacetState
}

const getOptionParts = (option: string, delimiter: string) => {
  const rawValues = option.split(delimiter)

  if (rawValues.length === 1) {
    const [nextOption] = rawValues
    return { nextOption }
  } else {
    const [prefix, ...optionParts] = rawValues
    return {
      prefix,
      // Anything after the first delimiter is a valid part of the value
      nextOption: optionParts.join(delimiter),
    }
  }
}

const parseFacetString = (facets?: string | string[]): SelectedFacetState => {
  if (!facets) {
    return DEFAULT_SEARCH_VALUES[QueryParamKeys.SelectedFacets]
  }

  const facetSet = Array.isArray(facets)
    ? facets
    : facets.split(DELIMITERS.FACETS)

  return facetSet.reduce((facets, facet) => {
    const [key, optionString] = facet.split(DELIMITERS.OPTION_SETS)
    const options = optionString.split(DELIMITERS.OPTIONS).map((option) => {
      const { prefix, nextOption } = getOptionParts(
        option,
        DELIMITERS.URL_PREFIXES
      )

      if (prefix) {
        return `${prefix}${DELIMITERS.VALUE_PREFIXES}${nextOption}`
      }

      return nextOption
    })
    facets[key] = options

    return facets
  }, {})
}

const buildFacetString = (facets: SelectedFacetState) => {
  return Object.keys(facets)
    .map((facetKey) => {
      const facetOptions = facets[facetKey]
        .map((option) => {
          const { prefix, nextOption } = getOptionParts(
            option,
            DELIMITERS.VALUE_PREFIXES
          )

          if (prefix) {
            return `${prefix}${DELIMITERS.URL_PREFIXES}${nextOption}`
          }

          return nextOption
        })
        .join(DELIMITERS.OPTIONS)
      return `${facetKey}${DELIMITERS.OPTION_SETS}${facetOptions}`
    })
    .join(DELIMITERS.FACETS)
}

const getStringFromStringSet = (set: string | string[]) => {
  return Array.isArray(set) ? set.join(',') : set
}

const getSortFromStringSet = (set: string | string[]) => {
  const sortString = getStringFromStringSet(set)
  return Object.values(Sort).find((v) => v === sortString)
}

const getNumberFromStringSet = (set: string | string[]) => {
  const stringValue = Array.isArray(set) ? set[0] : set

  return parseInt(stringValue, 10)
}

const getParameters = (
  query: ParsedUrlQuery,
  defaultSort: Sort | undefined = DEFAULT_SEARCH_VALUES[QueryParamKeys.Sort],
  defaultPerPage: number | undefined = DEFAULT_SEARCH_VALUES[
    QueryParamKeys.PerPage
  ]
): QueryState => {
  const searchTerm =
    getStringFromStringSet(query[QueryParamKeys.SearchTerm]) ?? null
  const searchedFor =
    getStringFromStringSet(query[QueryParamKeys.SearchedFor]) ?? null
  const page = getNumberFromStringSet(
    query[QueryParamKeys.Page] ?? DEFAULT_SEARCH_VALUES[QueryParamKeys.Page]
  )
  const perPage = getNumberFromStringSet(
    query[QueryParamKeys.PerPage] ?? defaultPerPage
  )
  const sort = getSortFromStringSet(query[QueryParamKeys.Sort]) ?? defaultSort
  const selectedFacets = parseFacetString(query[QueryParamKeys.SelectedFacets])
  return {
    searchTerm,
    searchedFor,
    page,
    perPage,
    sort,
    selectedFacets,
  }
}

const buildSetParameters =
  (router: LocalizedSingletonRouter) =>
  (nextParameters: Partial<ProductSearchQueryParams>) => {
    const nextQuery = Object.keys(nextParameters).reduce(
      (query, property) => {
        let value = nextParameters[property]
        const isSelectedFacets = property === QueryParamKeys.SelectedFacets
        const hasNoSelectedFacets =
          isSelectedFacets && Object.keys(value).length === 0

        if (!value || hasNoSelectedFacets) {
          if (query[property]) {
            delete query[property]
          }

          return query
        }

        if (isSelectedFacets) {
          value = buildFacetString(value)
        }

        query[property] = value

        return query
      },
      { ...router.query }
    )

    router.push(
      { pathname: router.pathname, query: nextQuery },
      { pathname: router.pathname, query: nextQuery },
      { shallow: true }
    )
  }

/**
 * `useCategoryProductSearchQueryParams` should be considered private to `useCategoryProductSearch`
 * It is not suitable for more generic usage. Use `useCategoryProductSearch` to access
 * view-ready values for the data returned here.
 */
const useCategoryProductSearchQueryParams = (
  defaultSort: Sort | undefined,
  defaultPerPage: number | undefined
) => {
  const router = useRouter()
  const setQueryParams = buildSetParameters(router)
  const { searchTerm, searchedFor, page, perPage, sort, selectedFacets } =
    getParameters(router.query, defaultSort, defaultPerPage)

  return {
    searchTerm,
    searchedFor,
    page,
    perPage,
    sort,
    selectedFacets,
    setQueryParams,
  }
}

export default useCategoryProductSearchQueryParams
