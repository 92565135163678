// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CategoryProductSearchQueryVariables = Types.Exact<{
  searchTerm?: Types.InputMaybe<Types.Scalars['String']>;
  page: Types.Scalars['Int'];
  perPage: Types.Scalars['Int'];
  sort?: Types.InputMaybe<Types.Sort>;
  selectedFacets?: Types.InputMaybe<Array<Types.FacetInput> | Types.FacetInput>;
  facetSet?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
}>;


export type CategoryProductSearchQuery = { __typename?: 'Query', getProductSearchResults: { __typename?: 'ProductSearchResults', metadata: { __typename?: 'SearchResultsMetadata', itemCount?: number | null, page: number, perPage: number, numPages: number }, items: Array<{ __typename?: 'Gene' } | { __typename?: 'Paper' } | { __typename?: 'Product', name: string, displaySellerName?: string | null, productNumber: string, productKey: string, isMarketplace: boolean, marketplaceOfferId?: string | null, marketplaceSellerId?: string | null, description?: string | null, paMessage?: string | null, legalName?: string | null, legalNameSuffix?: string | null, sdsLanguages: Array<string | null>, sdsPnoKey: string, attributes: Array<{ __typename?: 'ProductAttribute', key: string, label: string, values: Array<string> }>, brand: { __typename?: 'Brand', key: string, erpKey: string, name?: string | null, color?: string | null }, images: Array<{ __typename?: 'CatalogImage', altText?: string | null, smallUrl: string, mediumUrl: string, largeUrl: string }> } | { __typename?: 'Substance' } | { __typename?: 'TechnicalDocument' }>, facets: Array<{ __typename?: 'Facet', key: string, numToDisplay: number, isHidden?: boolean | null, isCollapsed: boolean, multiSelect: boolean, prefix?: string | null, options: Array<{ __typename?: 'FacetOptions', value: string, count: number }> }> } };

export type CategoryProductSearchFieldsFragment = { __typename?: 'ProductSearchResults', metadata: { __typename?: 'SearchResultsMetadata', itemCount?: number | null, page: number, perPage: number, numPages: number }, items: Array<{ __typename?: 'Gene' } | { __typename?: 'Paper' } | { __typename?: 'Product', name: string, displaySellerName?: string | null, productNumber: string, productKey: string, isMarketplace: boolean, marketplaceOfferId?: string | null, marketplaceSellerId?: string | null, description?: string | null, paMessage?: string | null, legalName?: string | null, legalNameSuffix?: string | null, sdsLanguages: Array<string | null>, sdsPnoKey: string, attributes: Array<{ __typename?: 'ProductAttribute', key: string, label: string, values: Array<string> }>, brand: { __typename?: 'Brand', key: string, erpKey: string, name?: string | null, color?: string | null }, images: Array<{ __typename?: 'CatalogImage', altText?: string | null, smallUrl: string, mediumUrl: string, largeUrl: string }> } | { __typename?: 'Substance' } | { __typename?: 'TechnicalDocument' }>, facets: Array<{ __typename?: 'Facet', key: string, numToDisplay: number, isHidden?: boolean | null, isCollapsed: boolean, multiSelect: boolean, prefix?: string | null, options: Array<{ __typename?: 'FacetOptions', value: string, count: number }> }> };

export type CategorySubstanceProductFieldsFragment = { __typename?: 'Product', name: string, displaySellerName?: string | null, productNumber: string, productKey: string, isMarketplace: boolean, marketplaceOfferId?: string | null, marketplaceSellerId?: string | null, description?: string | null, paMessage?: string | null, legalName?: string | null, legalNameSuffix?: string | null, sdsLanguages: Array<string | null>, sdsPnoKey: string, attributes: Array<{ __typename?: 'ProductAttribute', key: string, label: string, values: Array<string> }>, brand: { __typename?: 'Brand', key: string, erpKey: string, name?: string | null, color?: string | null }, images: Array<{ __typename?: 'CatalogImage', altText?: string | null, smallUrl: string, mediumUrl: string, largeUrl: string }> };

export const CategorySubstanceProductFieldsFragmentDoc = gql`
    fragment CategorySubstanceProductFields on Product {
  name
  displaySellerName
  productNumber
  productKey
  isMarketplace
  marketplaceOfferId
  marketplaceSellerId
  attributes {
    key
    label
    values
  }
  brand {
    key
    erpKey
    name
    color
  }
  images {
    altText
    smallUrl
    mediumUrl
    largeUrl
  }
  description
  paMessage
  legalName
  legalNameSuffix
  sdsLanguages
  sdsPnoKey
}
    `;
export const CategoryProductSearchFieldsFragmentDoc = gql`
    fragment CategoryProductSearchFields on ProductSearchResults {
  metadata {
    itemCount
    page
    perPage
    numPages
  }
  items {
    ... on Product {
      ...CategorySubstanceProductFields
    }
  }
  facets {
    key
    numToDisplay
    isHidden
    isCollapsed
    multiSelect
    prefix
    options {
      value
      count
    }
  }
}
    ${CategorySubstanceProductFieldsFragmentDoc}`;
export const CategoryProductSearchDocument = gql`
    query CategoryProductSearch($searchTerm: String, $page: Int!, $perPage: Int!, $sort: Sort, $selectedFacets: [FacetInput!], $facetSet: [String]) {
  getProductSearchResults(input: {searchTerm: $searchTerm, pagination: {page: $page, perPage: $perPage}, sort: $sort, group: product, facets: $selectedFacets, facetSet: $facetSet}) {
    ...CategoryProductSearchFields
  }
}
    ${CategoryProductSearchFieldsFragmentDoc}`;
export type CategoryProductSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CategoryProductSearchQuery, CategoryProductSearchQueryVariables>, 'query'> & ({ variables: CategoryProductSearchQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CategoryProductSearchComponent = (props: CategoryProductSearchComponentProps) => (
      <ApolloReactComponents.Query<CategoryProductSearchQuery, CategoryProductSearchQueryVariables> query={CategoryProductSearchDocument} {...props} />
    );
    

/**
 * __useCategoryProductSearchQuery__
 *
 * To run a query within a React component, call `useCategoryProductSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryProductSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryProductSearchQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      selectedFacets: // value for 'selectedFacets'
 *      facetSet: // value for 'facetSet'
 *   },
 * });
 */
export function useCategoryProductSearchQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CategoryProductSearchQuery, CategoryProductSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CategoryProductSearchQuery, CategoryProductSearchQueryVariables>(CategoryProductSearchDocument, options);
      }
export function useCategoryProductSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoryProductSearchQuery, CategoryProductSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CategoryProductSearchQuery, CategoryProductSearchQueryVariables>(CategoryProductSearchDocument, options);
        }
export type CategoryProductSearchQueryHookResult = ReturnType<typeof useCategoryProductSearchQuery>;
export type CategoryProductSearchLazyQueryHookResult = ReturnType<typeof useCategoryProductSearchLazyQuery>;
export type CategoryProductSearchQueryResult = ApolloReactCommon.QueryResult<CategoryProductSearchQuery, CategoryProductSearchQueryVariables>;