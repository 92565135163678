import { useEffect, useRef } from 'react'

export const useIntersectionObserver = <T extends Element>(
  callback: IntersectionObserverCallback,
  options: IntersectionObserverInit
) => {
  const targetRef = useRef<T | null>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options)

    if (targetRef.current) {
      observer.observe(targetRef.current)
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current)
      }
    }
  }, [targetRef, callback, options])

  return targetRef
}
