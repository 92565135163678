import React from 'react'
import { useIntl } from 'react-intl'
import { MapTo } from '@adobe/aem-react-editable-components'
import aemMessages from '@src/aem-content/messages'
import PressReleaseGrid from '@sial/aem-cms-components/dist/PressReleaseGrid/PressReleaseGrid'

const PressReleaseGridAdapter: React.FC<any> = (props) => {
  const intl = useIntl()
  const morePress = intl.formatMessage(aemMessages['MORE_PRESS_LINK_TITLE'])
  const latestPressReleases = intl.formatMessage(
    aemMessages['LATEST_PRESS_RELEASES']
  )

  const translations = {
    latestPressReleases,
    morePress,
  }

  return <PressReleaseGrid {...props} translations={translations} />
}

export default MapTo('cms-commons/components/content/pressreleasegrid')(
  PressReleaseGridAdapter
)
