import React, { useEffect, useState } from 'react'
import {
  MapTo,
  MappedComponentProperties,
} from '@adobe/aem-react-editable-components'
import { homeRoute, useRouter } from '@src/routes'
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles'
import DOMPurify from 'isomorphic-dompurify'
import { useCurrentUser } from '@src/utils/useCurrentUser'
import clsx from 'clsx'
import { useMediaQuery } from '@material-ui/core'
import { Link } from '@src/components/Link'

interface TextBannerProps extends MappedComponentProperties {
  textBannerDescription?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  textWrapper: {
    padding: theme.spacing(4, 0),
    display: 'flex',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 900,
    textAlign: 'center',
    letterSpacing: '0.09px',
    visibility: 'hidden',
    width: '100vw',
    margin: '0 -50vw',
    position: 'relative',
    left: '50%',
    right: '50%',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
    '& p': {
      marginBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  },
  displayBanner: {
    visibility: 'visible',
    backgroundColor: theme.palette.background.grey,
    color: 'inherit',
  },
}))

const TextBanner: React.FC<TextBannerProps> = ({ textBannerDescription }) => {
  const classes = useStyles()
  const router = useRouter()
  const theme = useTheme()
  const [modifiedText, setModifiedText] = useState<string>(
    textBannerDescription ?? ''
  )
  const { userIsLoggedIn } = useCurrentUser()
  const [showBanners, setShowBanners] = useState<boolean>(false)
  const [isLoginBanner, setIsLoginBanner] = useState<boolean>(false)
  const [signInRedirect, setSignInRedirect] = useState<string>('')
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  useEffect(() => {
    // Create a temporary element (e.g., div) to parse the HTML string
    const tempElement = document.createElement('div')
    tempElement.innerHTML = modifiedText

    // Find the anchor tag within the temporary element
    const isLoginAnchorTag = tempElement.querySelector('a[href$="/login"]')

    // Extract the href attribute value
    if (isLoginAnchorTag) {
      if (!userIsLoggedIn) {
        const hrefValue = isLoginAnchorTag.getAttribute('href') || ''
        // Create a new Link component
        const linkElement = document.createElement('a')
        const redirectURL = `${hrefValue}?redirect=${encodeURIComponent(
          router.asPath || homeRoute.index()
        )}&icid=sign_in_prompt;body;banner;link`
        linkElement.setAttribute('href', redirectURL)
        linkElement.textContent = isLoginAnchorTag.textContent // Copy anchor tag content to Link

        // Replace the anchor tag with the new Link component
        isLoginAnchorTag?.parentNode &&
          isLoginAnchorTag?.parentNode.replaceChild(
            linkElement,
            isLoginAnchorTag
          )
        setModifiedText(tempElement.innerHTML)
        setShowBanners(true)
        setIsLoginBanner(true)
        setSignInRedirect(redirectURL)
      } else {
        setShowBanners(false)
      }
    } else {
      setShowBanners(true)
    }
  }, [textBannerDescription])

  if (!textBannerDescription) return null

  return (
    <>
      {isMobile && isLoginBanner ? (
        <Link href={signInRedirect} passHref>
          <div
            className={clsx(
              classes.textWrapper,
              showBanners && classes.displayBanner
            )}
          >
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(modifiedText),
              }}
            />
          </div>
        </Link>
      ) : (
        <div
          className={clsx(
            classes.textWrapper,
            showBanners && classes.displayBanner
          )}
        >
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(modifiedText),
            }}
          />
        </div>
      )}
    </>
  )
}

export default MapTo('cms-commons/components/content/textbanner')(TextBanner)
