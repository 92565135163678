import { MapTo } from '@adobe/aem-react-editable-components'
import React, { useEffect, useContext } from 'react'
import { CMSCommonContainer } from '@sial/aem-cms-components/dist/Container/Container'
import { useCurrentUser } from '@utils/useCurrentUser'
import { AemContext } from '@src/aem-content/components/AemContext'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

const withHooksHOC = (Component: any) => {
  const MyComp = (props) => {
    const { userIsLoggedIn } = useCurrentUser()

    const { setIsHalfGatedContentActive } = useContext(AemContext)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

    useEffect(() => {
      if (props.halfGatedContent && !userIsLoggedIn) {
        setIsHalfGatedContentActive(true)
      }
    }, [])

    return (
      <Component
        userIsLoggedIn={userIsLoggedIn}
        isMobile={isMobile}
        {...props}
      />
    )
  }
  MyComp.displayName = 'CMSCommonContainerHOC'
  return MyComp
}

export default MapTo('cms-commons/components/content/container')(
  withHooksHOC(CMSCommonContainer)
)
