import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: 1320,
    margin: '0 auto',
    padding: theme.spacing(0, 5),
  },
}))

export const EmbeddedSurvey = () => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <div id="aem-embed-survey" />
    </div>
  )
}
