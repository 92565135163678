import React, { FC, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { SelectorTool } from '@src/components/SelectorTool'
import selectorToolMessages from '@utils/MessageSets/defineSelectorToolMessages'
import { selectorToolMapper } from '@src/components/SelectorTool/selectorToolMapper'
import { useProductSearchQuery } from '@src/queries/ProductSearchQuery.generated'
import {
  FacetInput,
  ProductSearchGroup,
  ProductSearchType,
  Sort,
} from '@src/types/graphql-types'
import { useSelectorToolSearch } from '@src/components/SelectorTool/useSelectorToolSearch'
import { isServer } from '@src/utils/isServer'
import { useProductCatalogFilter } from '@src/utils/useProductCatalogFilter'
import { sendSelectorToolEvent } from '@src/utils/analytics'

type AntibodySelectorToolProps = {
  onClose: () => void
  onDismiss: () => void
  open: boolean
  toolType: string
}

export const AntibodySelectorTool: FC<AntibodySelectorToolProps> = ({
  onClose,
  onDismiss,
  open,
  toolType,
}) => {
  const { formatMessage } = useIntl()
  const selectedTool = selectorToolMapper[toolType]
  const [handleSelectorSearch] = useSelectorToolSearch()
  const productCatalogFilter = useProductCatalogFilter()
  const isAntibodyTool = toolType === 'antibody'
  const fetchPolicy = !isServer() ? 'cache-first' : 'network-only'

  const { data, refetch } = useProductSearchQuery({
    fetchPolicy,
    variables: {
      searchTerm: toolType,
      page: 1,
      group: ProductSearchGroup.Substance,
      selectedFacets: [
        {
          key: 'facet_product_category',
          options: ['antibodies'],
        },
      ],
      sort: Sort.Relevance,
      type: ProductSearchType.Product,
      filter: productCatalogFilter,
    },
  })

  const { facets = [] } = data?.getProductSearchResults || {}

  // Facets being constructed to get passed to the SelectorTool
  const selectorToolFacets = useMemo(
    () =>
      selectedTool?.facets?.map((selectedFacet) => {
        /**
         * Getting the facet information from the mapper to
         * one of the actual facets that we get from the list
         * of all the facets from the getProductSearchResults
         */
        const mappedFacet = facets?.find(
          (facet) => facet.key === selectedFacet.key
        )

        return {
          ...(mappedFacet || {}),
          ...selectedFacet,
          label: formatMessage(selectorToolMessages[selectedFacet.label]),
          /**
           * Mapping through the facet options from the data.getProductSearchResults
           * and returning a label value in order to pass it into the
           * <SelectorTool /> component
           */
          options:
            mappedFacet?.options?.map((facetOption) => ({
              ...facetOption,
              label: facetOption.value,
            })) ?? [],
        }
      }),
    [facets, selectedTool]
  )

  /**
   * If there is a search for the antibody, need to append
   * the term antibody to the end of the search term. If no
   * search is entered and still antibody search, JUST
   * the term antibody is used by default
   */
  const handleSearchClick = useCallback(
    (term?: string, selectedFacets?: string[] | null) => {
      /**
       * We want the antibody facets to be powered by the
       * antibodies facet on the SRP. If it is an antibody
       * that is being searched for, then we need to make
       * sure and add the facet_product_category of
       * antibodies along with any other facet that may
       * be selected by the user
       */
      const newSelectedFacets = [
        ...(Array.isArray(selectedFacets) ? selectedFacets : []),
        ...(isAntibodyTool ? ['facet_product_category:antibodies'] : []),
      ]

      const searchTerm = !term ? toolType : term

      handleSelectorSearch({}, searchTerm, newSelectedFacets)
      const selectedFacetMap = {}
      selectedFacets?.forEach((selectedFacet) => {
        const facetForHashMap = selectedFacet.split(':')

        const [facetKey, facetValue] = facetForHashMap
        selectedFacetMap[facetKey]
          ? selectedFacetMap[facetKey].push(facetValue)
          : (selectedFacetMap[facetKey] = [facetValue])
      })

      sendSelectorToolEvent({
        action: 'show antibody products',
        detail: `antigen/antibody name : ${searchTerm} > clonality : ${
          selectedFacetMap['facet_clonality'] || ''
        } > technique : ${
          selectedFacetMap['facet_web_titertest_app'] || ''
        } > species reactivity : ${
          selectedFacetMap['facet_web_species_reactivity'] || ''
        } > conjugate : ${
          selectedFacetMap?.['facet_web_conjugate']?.join(',') || ''
        }`,
        section: 'antibody product selector',
        component: 'slider',
        elementType: 'button',
        elementText: 'show products',
        productId: '',
        productBrand: '',
        productName: '',
      })
    },
    [handleSelectorSearch, {}, toolType]
  )

  const handleRefetchFacets = useCallback((newSelectedFacets: string[]) => {
    const facetHashMap = {}

    newSelectedFacets.forEach((newSelectedFacet) => {
      const facetForHashMap = newSelectedFacet.split(':')
      const [facetKey, facetValue] = facetForHashMap
      const facetInHashMap = facetHashMap?.[facetKey]

      facetHashMap[facetKey] = {
        key: facetKey,
        options:
          facetKey in facetHashMap
            ? [...facetInHashMap.options, facetValue]
            : [facetValue],
      }
    })

    refetch({
      searchTerm: toolType,
      page: 1,
      group: ProductSearchGroup.Substance,
      selectedFacets: [
        ...(Object.values(facetHashMap) as FacetInput[]),
        {
          key: 'facet_product_category',
          options: ['antibodies'],
        },
      ],
      sort: Sort.Relevance,
      type: ProductSearchType.Product,
      filter: productCatalogFilter,
    })
  }, [])

  return (
    <SelectorTool
      onClose={onClose}
      onDismiss={onDismiss}
      onFetchNewFacets={handleRefetchFacets}
      onSearchClick={handleSearchClick}
      open={open}
      selectorDescription={formatMessage(
        selectorToolMessages[selectedTool.description]
      )}
      selectorTitle={formatMessage(selectorToolMessages[selectedTool.title])}
      selectorToolFacets={selectorToolFacets}
      textFieldSearchLabel={formatMessage(
        selectorToolMessages[selectedTool.searchLabel]
      )}
    />
  )
}
