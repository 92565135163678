import { MapTo } from '@adobe/aem-react-editable-components'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import HorizontalTabContainer from '@sial/aem-cms-components/dist/HorizontalTab/HorizontalTabContainer'
import { useCurrentUser } from '@utils/useCurrentUser'
import { AemContext } from '../AemContext'

const withHooksHOC = (Component: any) => {
  const MyComp = (props) => {
    const { userIsLoggedIn } = useCurrentUser()
    const { isBuyAgainEmpty } = useContext(AemContext)
    const intl = useIntl()
    const popularProducts = intl.formatMessage({
      id: 'POPULAR_PRODUCTS',
      defaultMessage: 'Popular Products',
    })
    const translations = {
      popularProducts,
    }
    return (
      <Component
        userIsLoggedIn={userIsLoggedIn}
        isBuyAgainEmpty={isBuyAgainEmpty}
        translations={translations}
        {...props}
      />
    )
  }
  MyComp.displayName = 'HorizontalTabHOC'
  return MyComp
}

export default MapTo('cms-commons/components/content/horizontaltab')(
  withHooksHOC(HorizontalTabContainer)
)
