import React from 'react'
import { useRouter } from 'next/router'
import { MapTo } from '@adobe/aem-react-editable-components'
import { useMetadataForFfCertificatesQuery } from '@src/queries/FFMetadataQuery.generated'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  brandLogo: {
    width: 'auto',
    height: 'auto',
  },
  brandLogoWrapper: {
    margin: theme.spacing(8, 0),
  },
}))

const FFCertsBrandImage = () => {
  const router = useRouter()
  const classes = useStyles()
  const path = router.query
  const brandKey = path.cmsRoute[3] || ''
  const productKey = path.cmsRoute[4] || ''

  const { data } = useMetadataForFfCertificatesQuery({
    variables: {
      input: {
        productKey,
        brandKey,
      },
    },
    fetchPolicy: 'no-cache',
  })

  const product = data?.getMetadataForFFCertificates

  return (
    <>
      <div className={classes.brandLogoWrapper}>
        <img
          className={classes.brandLogo}
          alt={product?.logo?.altText || ''}
          src={product?.logo?.largeUrl}
          width="120"
          height="15"
        />
      </div>
    </>
  )
}

export default MapTo('cms-commons/components/content/ffcertsbrandimage')(
  FFCertsBrandImage
)
