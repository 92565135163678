import React from 'react'
import { useIntl } from 'react-intl'
import { MapTo } from '@adobe/aem-react-editable-components'
import messages from '@src/utils/messages'
import aemMessages from '@src/aem-content/messages'
import WebinarSidebar from '@sial/aem-cms-components/dist/WebinarSidebar/WebinarSidebar'

const WebinarSidebarAdapter: React.FC<any> = (props) => {
  const intl = useIntl()
  const duration = intl.formatMessage(aemMessages['WEBINAR_DURATION_LABEL'])
  const language = intl.formatMessage(messages['LANGUAGE'])
  const presented = intl.formatMessage(aemMessages['WEBINAR_PRESENTED_LABEL'])
  const session = intl.formatMessage(aemMessages['WEBINAR_SESSION_LABEL'])

  const translations = {
    duration,
    language,
    presented,
    session,
  }

  return <WebinarSidebar {...props} translations={translations} />
}

export default MapTo('cms-commons/components/content/webinarsidebar')(
  WebinarSidebarAdapter
)
