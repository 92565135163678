import React, { FC, useState, useEffect } from 'react'
import { uniqueId as _uniqueId } from 'lodash'
import { parse } from 'querystring'
import Head from 'next/head'
import FormAssemblyHeader from './FormAssemblyHeader'
import { useScript } from 'usehooks-ts'
import { isServer } from '@src/utils/isServer'
import clsx from 'clsx'

interface Props {
  formId: number
  formAssemblyUrl?: string
  anchorId?: string
  activatePrefill?: boolean
  formFields?: object
  isFormMounted?: (v: boolean) => void
  formStyleClass?: string
}

const isClient = typeof window !== 'undefined'

const FormAssembly: FC<Props> = (props) => {
  const {
    formId,
    formAssemblyUrl = 'http://localhost:8080/https://sial.tfaforms.net',
    formFields = {},
    anchorId,
    isFormMounted,
    formStyleClass,
  } = props

  const scriptStatus = useScript(
    'https://sial.tfaforms.net/wForms/3.11/js/wforms.js?v=813cb4fab17d46c586bbe42b1bcc8e38ca00cf31',
    { removeOnUnmount: false }
  )
  const [html, setHtml] = useState('')
  const uniqueId = _uniqueId('form-assembly-')
  const [isMounted, setIsMounted] = useState(false)
  const [isWFORMSLoaded, setIsWFORMSLoaded] = useState(
    isClient ? !!window?.wFORMS : false
  )

  const isWformsScriptReady = isClient && scriptStatus === 'ready'

  const handleWFORMSLoaded = () => {
    setIsWFORMSLoaded(true)
  }

  const encodeParam = () => {
    const params: string = Object.keys(formFields).reduce((previous, key) => {
      if (!formFields[key]) return previous
      return `${previous}&${key}=${encodeURIComponent(formFields[key])}`
    }, '')

    return params.replace(/\s/g, '')
  }

  useEffect(() => {
    let formUrl
    const d: number = +new Date()
    if (formId > 0) {
      if (isClient) {
        if (window.location.pathname.startsWith('/content/cms-commons')) {
          formUrl = `/bin/cms-commons/forms-api?formId=${formId}&d=${d}`
        } else {
          formUrl = `/forms-api/${formId}?d=${d}${encodeParam()}`
        }
      }
      if (isClient && window.location.search) {
        const queryParams = parse(
          window.location.search.startsWith('?')
            ? window.location.search.split('?')[1]
            : window.location.search
        )
        if (queryParams.tfa_next) {
          formUrl = `${formAssemblyUrl}/rest${decodeURI(
            queryParams.tfa_next as string
          )}`
        } else {
          formUrl += window.location.search.startsWith('?')
            ? window.location.search
            : `?${window.location.search}`
        }
      }
      if (isWformsScriptReady) {
        fetch(formUrl).then((response) =>
          response.text().then((text) => {
            const bodyHtml =
              text && text.split('<!-- FORM: BODY SECTION -->')[1]
            setHtml(bodyHtml)
            if (typeof isFormMounted === 'function') {
              isFormMounted(true)
            }
          })
        )
      }
    }
  }, [formId, formAssemblyUrl, isWformsScriptReady])
  useEffect(() => {
    handleWFORMSLoaded()
    setIsMounted(true)
    isClient && window.addEventListener('load', handleWFORMSLoaded)
    return () => {
      isClient && window.removeEventListener('load', handleWFORMSLoaded)

      if (typeof isFormMounted === 'function') {
        isFormMounted(false)
      }
    }
  }, [])

  useEffect(() => {
    if (
      isClient &&
      window.wFORMS &&
      window.wFORMS.onLoadHandler &&
      html?.length
    )
      window.wFORMS.onLoadHandler()
  }, [html])
  return (
    <>
      {isClient && window.wFORMS && (
        <Head>
          <FormAssemblyHeader />
        </Head>
      )}
      {!isServer() && isMounted && isWFORMSLoaded ? (
        <div
          id={anchorId}
          className={clsx(uniqueId, formStyleClass)}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: html }}
        />
      ) : null}
    </>
  )
}
export default FormAssembly
