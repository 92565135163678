import React from 'react'
import { useIntl } from 'react-intl'
import { MapTo } from '@adobe/aem-react-editable-components'
import messages from '@src/utils/messages'
import Contact from '@sial/aem-cms-components/dist/Contact/Contact'

const ContactAdapter: React.FC<any> = (props) => {
  const intl = useIntl()

  const email = intl.formatMessage(messages['EMAIL'])
  const phone = intl.formatMessage(messages['PHONE'])

  const translations = {
    email,
    phone,
  }

  return <Contact {...props} translations={translations} />
}

export default MapTo('cms-commons/components/content/contact')(ContactAdapter)
