/*
    Dynamic Product Table.tsx

    Maps to cms-commons/components/content/dynamicproducttable
*/

import React, { Component } from 'react'
import { MapTo } from '@adobe/aem-react-editable-components'
import ProductTable from './DynamicProductTableWrapper'
import { ProductInput } from '@src/types/graphql-types'

export interface AemAdditionalColumns {
  text: string
  value: string
}
export interface AemDynamicProductTableProps {
  tableTitle: string
  products: ProductInput[]
  maxRows?: number
  isShowImage?: boolean
  isShowBrand?: boolean
  isStaticContent?: boolean
  additionalColumns: AemAdditionalColumns[]
  anchorId?: string
  isInEditor: boolean
  cqPath: string
}

/**
 * Dynamic Product Table React component
 */
export default class DynamicProductTableAdapter extends Component<AemDynamicProductTableProps> {
  render() {
    return <ProductTable {...this.props} isStaticContent />
  }
}

MapTo<AemDynamicProductTableProps>(
  'cms-commons/components/content/dynamicproducttable'
)(DynamicProductTableAdapter)
