import React, { useState, createContext } from 'react'

interface AemContextInterface {
  isHalfGatedContentActive: boolean
  setIsHalfGatedContentActive: (boolean) => void
  isBuyAgainEmpty: boolean
  setIsBuyAgainEmpty: (boolean) => void
}

export const AemContext = createContext<AemContextInterface>({
  isHalfGatedContentActive: false,
  setIsHalfGatedContentActive: () => {
    // Added comment for avoid es-lint error
  },
  isBuyAgainEmpty: false,
  setIsBuyAgainEmpty: () => {
    // Added comment for avoid es-lint error
  },
})

export const AemContextProvider = ({ children }) => {
  const [isHalfGatedContentActive, setIsHalfGatedContentActive] =
    useState(false)
  const [isBuyAgainEmpty, setIsBuyAgainEmpty] = useState(false)
  return (
    <AemContext.Provider
      value={{
        isHalfGatedContentActive,
        setIsHalfGatedContentActive,
        isBuyAgainEmpty,
        setIsBuyAgainEmpty,
      }}
    >
      {children}
    </AemContext.Provider>
  )
}
