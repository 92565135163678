import React from 'react'
import { useIntl } from 'react-intl'
import { MapTo } from '@adobe/aem-react-editable-components'
import aemMessages from '@src/aem-content/messages'
import RelatedWebinar from '@sial/aem-cms-components/dist/RelatedWebinar/RelatedWebinar'

const RelatedWebinarAdapter: React.FC<any> = (props) => {
  const intl = useIntl()
  const moreWebinars = intl.formatMessage(
    aemMessages['MORE_WEBINARS_LINK_TITLE']
  )
  const relatedWebinars = intl.formatMessage(aemMessages['RELATED_WEBINARS'])

  const translations = {
    moreWebinars,
    relatedWebinars,
  }

  return <RelatedWebinar {...props} translations={translations} />
}

export default MapTo('cms-commons/components/content/relatedwebinar')(
  RelatedWebinarAdapter
)
