import React, { useState } from 'react'
import { MapTo } from '@adobe/aem-react-editable-components'
import { makeStyles } from '@material-ui/core/styles'
import { Collapse, Theme } from '@material-ui/core'
import { FormattedMessage, useIntl } from 'react-intl'
import messages from '@utils/messages'
import * as yup from 'yup'
import RequestLicenseSection, {
  RequestLicenseSectionProps,
} from './RequestLicenseSection'
import { useCurrentUser } from '@src/utils/useCurrentUser'
import { ApolloQueryResult } from 'apollo-client'
import CaretRightIcon from '@src/icons/CaretRightIcon'
import clsx from 'clsx'
import BaseLicenseSection, {
  BaseLicenseSectionProps,
} from './BaseLicenseSection'
import { loginRoute, softwareLibraryRoutes, useRouter } from '@src/routes'

export const useStyles = makeStyles((theme: Theme) => ({
  pointerCursor: {
    cursor: 'pointer',
  },
  licenseSection: {
    padding: '12px 36px 0px 36px',
    background: theme.palette.background.grey,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    lineHeight: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '320px',
    '& > div': {
      height: '90px',
    },
  },
  input: {
    marginBottom: '6px',
    '&::placeholder': {
      color: theme.palette.error.main,
    },
  },
  formButton: {
    alignSelf: 'flex-end',
    marginTop: '12px',
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(4),
    minWidth: '100%',
    fontSize: '16px',
    fontWeight: 'bold',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      minWidth: 166,
    },
  },
  requestLicenseButton: {
    margin: '2px 0 0 0',
    padding: '10px 15px 11px',
    minWidth: 'auto',
    height: '40px',
  },
  baseLicenseButton: {
    '&:first-of-type': {
      marginTop: '32px',
      color: '#ffffff',
      textDecoration: 'initial',
      height: '40px',
      padding: '10px 12px 11px',
      fontWeight: 600,
      width: '144px',
      '&:hover, &:active': {
        background: theme.palette.primary.main,
        textDecoration: 'initial',
        color: '#ffffff',
      },
    },
  },
  promptMessage: {
    '&:first-of-type': {
      margin: 0,
      lineHeight: '14px',
    },
  },
  headingSection: {
    position: 'relative',
  },
  iconContainer: {
    cursor: 'pointer',
    position: 'absolute',
    top: '8px',
    left: '-20px',
    display: 'flex',
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: '10px',
    fontWeight: 400,
  },
  expandedIcon: {
    transform: 'rotate(90deg)',
  },
  expandedContainer: {
    height: '216px',
  },
  subSection: {
    paddingTop: '8px',
  },
}))

export const TrialLicense = () => {
  const classes = useStyles()

  const intl = useIntl()

  const validationSchema = yup.object().shape({
    serialNumber: yup
      .string()
      .required(
        intl.formatMessage(messages.DIGITAL_PRODUCT_SERIAL_NUMBER_INVALID)
      )
      .length(
        10,
        intl.formatMessage(messages.EXACT_N_CHARACTERS, { quantity: 10 })
      )
      // only should contain digits
      .matches(
        /^\d+$/,
        intl.formatMessage(messages.DIGITAL_PRODUCT_SERIAL_NUMBER_INVALID)
      ),
  })

  const [queryResult, setQueryResult] = useState<ApolloQueryResult<any>>()
  const [expanded, setExpanded] = useState(false)
  const { userIsLoggedIn } = useCurrentUser()
  const router = useRouter()

  const baseSectionProps = {
    classes,
    validationSchema,
    intl,
    setQueryResult,
  }

  const sections = {
    loginPromptSection: {
      component: BaseLicenseSection,
      props: {
        ...baseSectionProps,
        heading: messages.LICENSE_KEY_LOGIN_PROMPT,
        buttonText: messages.LOGIN,
        buttonIdentifier: 'login-button',
        href: `${loginRoute.index()}?redirect=${encodeURIComponent(
          router.asPath
        )}`,
      },
    },
    generatedLicenseSection: {
      component: BaseLicenseSection,
      props: {
        ...baseSectionProps,
        heading: messages.A_LICENSE_KEY_HAS_BEEN_GENERATED,
        buttonText: messages.VIEW_MY_LICENSE,
        buttonIdentifier: 'generated-license-button',
        href: softwareLibraryRoutes.index(),
      },
    },
    requestLicenseSection: {
      component: RequestLicenseSection,
      props: baseSectionProps,
    },
  }

  const getLicenseSection = (): [
    React.ElementType,
    BaseLicenseSectionProps | RequestLicenseSectionProps,
  ] => {
    const {
      loginPromptSection,
      generatedLicenseSection,
      requestLicenseSection,
    } = sections

    const selectedSection = !userIsLoggedIn
      ? loginPromptSection
      : Boolean(queryResult?.data?.verifySoftwareLicense)
        ? generatedLicenseSection
        : requestLicenseSection

    return [selectedSection.component, selectedSection.props]
  }

  const licenseClasses = clsx(classes.licenseSection, {
    [classes.expandedContainer]: expanded,
  })
  const iconClasses = clsx(classes.icon, {
    [classes.expandedIcon]: expanded,
  })

  // to avoid being overwritten by AEM styles
  const headingStyles = {
    fontSize: '20px',
    textTransform: 'uppercase' as const,
    fontFamily: 'Lato',
    fontWeight: 900,
    lineHeight: 1.2,
    letterSpacing: '1px',
    textAlign: 'left' as const,
    paddingBottom: '10px',
    margin: 0,
  }

  const handleToggle = () => setExpanded((expanded) => !expanded)
  const [LicenseSection, licenseSectionProps] = getLicenseSection()

  return (
    <div className={licenseClasses}>
      <div className={classes.headingSection}>
        <div
          onClick={handleToggle}
          onKeyDown={handleToggle}
          className={classes.iconContainer}
          role="button"
          tabIndex={0}
        >
          <CaretRightIcon className={iconClasses} />
        </div>
        <h1 style={headingStyles}>
          <FormattedMessage {...messages.REQUEST_TRIAL_LICENSE} />
        </h1>
      </div>
      <Collapse in={expanded} timeout="auto">
        <LicenseSection {...licenseSectionProps} />
      </Collapse>
    </div>
  )
}

export default MapTo('cms-commons/components/content/dealerlicense')(
  TrialLicense
)
