import React from 'react'
import { useIntl } from 'react-intl'
import { MapTo } from '@adobe/aem-react-editable-components'
import messages from '@utils/messages'
import RelatedProductResources from '@sial/aem-cms-components/dist/RelatedProductResources'

const RelatedProductResourcesAdapter: React.FC<any> = (props) => {
  const intl = useIntl()
  const page = intl.formatMessage(messages['PAGE'])
  const pagination = intl.formatMessage(messages['PAGINATION_OPTION_DISPLAY'])
  const goNext = intl.formatMessage(messages['PAGINATION_LABEL_PREVIOUS'])
  const goPrevious = intl.formatMessage(messages['PAGINATION_LABEL_NEXT'])

  const translations = {
    page,
    pagination,
    goNext,
    goPrevious,
  }

  return <RelatedProductResources {...props} translations={translations} />
}

export default MapTo('cms-commons/components/content/relatedproductresources')(
  RelatedProductResourcesAdapter
)
