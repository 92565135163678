import React from 'react'
import clsx from 'clsx'
import { FormattedMessage, useIntl } from 'react-intl'
import { Sort } from '@src/types/graphql-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  Theme,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import messages from '@src/utils/messages'
import ResponsiveModal from '@src/components/ResponsiveModal'
import CSRFacets from './CSRFacets'
import CSRFacetGroup from './CSRFacetGroup'
import CSRSearchField from './CSRSearchField'
import { SortOption } from './CategorySearchResults'
import { SelectableFacet, FacetSelection } from './useCategoryProductSearch'

const useStyles = makeStyles((theme: Theme) => ({
  modalBody: {
    flex: 1,
    overflow: 'scroll',
    padding: theme.spacing(6, 4),
  },
  modalFooter: {
    padding: theme.spacing(6, 4),
  },
  loading: {
    opacity: '50%',
  },
  radioGroup: {
    flexDirection: 'column',
  },
  icon: {
    display: 'flex',
    width: '24px',
    height: '24px',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: '50%',
    '& span': {
      margin: 'auto',
      width: '14px',
      height: '14px',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
    },
  },
  resultsCount: {
    fontWeight: theme.typography.fontWeightMedium,
    marginLeft: theme.spacing(1),
  },
}))

interface CSRMobileActionsModalProps {
  loading: boolean
  itemCount?: number | null
  handleClose: () => void
  facets: SelectableFacet[]
  sort: Sort
  sortOptions: SortOption[]
  term?: string
  handleUpdateFacets: (facet: FacetSelection | {}) => void
  handleUpdateSort: (sort?: Sort | undefined) => void
  handleUpdateSearchTerm: (term?: string) => void
}

const CSRMobileActionsModal: React.FC<CSRMobileActionsModalProps> = ({
  loading,
  itemCount,
  handleClose,
  facets,
  sort,
  sortOptions,
  term,
  handleUpdateFacets,
  handleUpdateSort,
  handleUpdateSearchTerm,
}) => {
  const classes = useStyles()
  const intl = useIntl()
  return (
    <ResponsiveModal
      open
      onClose={handleClose}
      renderTitle={() => (
        <Typography variant="h2" component="h2">
          <FormattedMessage {...messages.FILTER_AND_SORT_HEADING} />
        </Typography>
      )}
    >
      <>
        <div
          className={clsx(classes.modalBody, {
            [classes.loading]: loading,
          })}
        >
          <CSRFacetGroup
            loading={loading}
            title={intl.formatMessage(messages.SORT_HEADING)}
          >
            <RadioGroup
              row
              className={classes.radioGroup}
              aria-label="sortOptions"
              name="sortOptions"
              value={sort}
              onChange={(e) => {
                const sort = Object.values(Sort).find(
                  (v) => v === e.target.value
                )
                handleUpdateSort(sort)
              }}
            >
              {sortOptions.map(({ value, label }, index) => (
                <FormControlLabel
                  name="sortOptionId"
                  value={value}
                  key={index}
                  disabled={loading}
                  label={intl.formatMessage(label)}
                  control={
                    <Radio
                      disableRipple
                      disableTouchRipple
                      disableFocusRipple
                      icon={<span className={classes.icon} />}
                      checkedIcon={
                        <span className={classes.icon}>
                          <span></span>
                        </span>
                      }
                    />
                  }
                />
              ))}
            </RadioGroup>
          </CSRFacetGroup>
          <CSRFacets
            loading={loading}
            facets={facets}
            handleUpdateFacets={handleUpdateFacets}
          />
          <CSRFacetGroup
            loading={loading}
            title={intl.formatMessage(messages.CONF_SEARCH_TERM)}
          >
            <CSRSearchField
              searchTerm={term}
              handleUpdateSearchTerm={handleUpdateSearchTerm}
            />
          </CSRFacetGroup>
        </div>
        <div
          className={clsx(classes.modalFooter, {
            [classes.loading]: loading,
          })}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            disabled={loading}
            onClick={handleClose}
          >
            <FormattedMessage id="DONE" defaultMessage="Done" />
            <span className={classes.resultsCount}>
              ({itemCount || 0} <FormattedMessage {...messages.RESULTS} />)
            </span>
          </Button>
        </div>
      </>
    </ResponsiveModal>
  )
}

export default CSRMobileActionsModal
