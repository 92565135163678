import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import getConfig from 'next/config'
import { OgImageMetaInfo } from '../AemStaticContent/AemStaticContent'

interface OgTagProps {
  title?: string
  description?: string
  ogImageMetaInfo?: OgImageMetaInfo[]
}

const OgMetaTags: React.FC<OgTagProps> = ({
  title,
  description,
  ogImageMetaInfo,
}) => {
  const { asPath } = useRouter()
  const {
    publicRuntimeConfig: { canonicalUrlPrefix, brandIdentity },
  } = getConfig()
  const defaultImagePath =
    brandIdentity && brandIdentity === 'MerckMillipore'
      ? '/static/social/blue-vibrant-m.png'
      : '/deepweb/assets/sigmaaldrich/marketing/global/images/social-media/vibrant-m/vibrant-m.jpg'

  const ogImage =
    (ogImageMetaInfo?.length && ogImageMetaInfo[0].ogImage) || defaultImagePath
  return (
    <Head>
      {title && <meta name="og:title" content={title} />}
      <meta name="og:type" content="website" />
      {ogImage && <meta name="og:image" content={ogImage} />}
      <meta name="og:url" content={`${canonicalUrlPrefix}${asPath}`} />
      {description && <meta name="og:description" content={description} />}
    </Head>
  )
}

export default OgMetaTags
