import React from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { ButtonBase, Theme } from '@material-ui/core'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import Carousel from 'nuka-carousel'
import { useCurrentUser } from '@utils/useCurrentUser'
import { MapTo } from '@adobe/aem-react-editable-components'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { UserEngageCard } from './UserEngageCard'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme: Theme) => ({
  welcomeWrapper: {
    marginBottom: `${theme.spacing(8)}px`,
    marginTop: `${theme.spacing(8)}px`,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(28),
  },
  caroselWrapper: {
    margin: '20px auto',
    padding: theme.spacing(6),
    borderRadius: '5px',
    background: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      border: '0 none',
      boxShadow: 'none',
      padding: 0,
      touchAction: 'pan-y',
    },
    '& .slider-frame .slide-current': {
      '@media (max-width: 450px)': {
        marginLeft: '-2%',
      },
      '@media (min-width: 451px) and (max-width: 600px)': {
        marginLeft: '-2%',
      },
    },
  },
  caroselWrapperShadow: {
    border: '1px solid rgba(201, 201, 201, 0.50)',
    boxShadow: '1px 2px 3px 0px rgba(0, 0, 0, 0.20)',
    [theme.breakpoints.down('xs')]: {
      border: '0 none',
      boxShadow: 'none',
      padding: 0,
    },
  },
  wrapper: {
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(0)} !important`,
    },
    '@media (max-width: 1350px)': {
      padding: theme.spacing(0, 5),
    },
  },
  itemWrapper: {
    fontSize: theme.typography.pxToRem(14),
    wordBreak: 'break-word',
    textAlign: 'center',
  },
  leftNavButton: {
    left: '-15px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  rightNavButton: {
    right: '-15px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  navArrow: {
    fontSize: theme.typography.pxToRem(28),
    color: theme.palette.secondary.main,
  },
  navArrowDisabled: {
    color: theme.palette.text.disabled,
  },
}))

const UserEngageCarousel: React.FC<any> = ({
  items,
  cardsPerRotation,
  autoplay = false,
  delay,
  autopauseDisabled,
  anchorId,
  shadow,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { currentUser } = useCurrentUser()
  const mediaQueryMdUp = useMediaQuery(theme.breakpoints.up('md'))
  let SlideToShowNumber = 3.5

  if (mediaQueryMdUp) {
    SlideToShowNumber = cardsPerRotation
  }
  return (
    <div
      id={anchorId}
      className={`aem-ms-cmp aem-ms-userEngage-carousel ${classes.wrapper}`}
    >
      {currentUser?.firstName && (
        <div className={classes.welcomeWrapper}>
          <FormattedMessage id="WELCOME" defaultMessage="Welcome" />{' '}
          {currentUser?.firstName}
        </div>
      )}
      <div
        className={clsx(
          classes.caroselWrapper,
          shadow && classes.caroselWrapperShadow
        )}
      >
        <Carousel
          autoplay={autoplay}
          autoplayInterval={delay}
          pauseOnHover={!autopauseDisabled}
          slidesToShow={SlideToShowNumber}
          slidesToScroll={SlideToShowNumber}
          cellSpacing={mediaQueryMdUp ? 32 : 8}
          wrapAround={items && items?.length >= cardsPerRotation}
          dragging={!mediaQueryMdUp}
          renderCenterLeftControls={(props) => {
            const disabled = props.currentSlide === 0
            return items && items.length > cardsPerRotation ? (
              <ButtonBase
                className={classes.leftNavButton}
                disabled={disabled}
                onClick={() => props.previousSlide()}
                aria-label="previous"
              >
                <KeyboardArrowLeft
                  className={clsx(
                    classes.navArrow,
                    disabled && classes.navArrowDisabled
                  )}
                />
              </ButtonBase>
            ) : null
          }}
          renderBottomCenterControls={null}
          renderCenterRightControls={(props) => {
            return items && items.length > cardsPerRotation ? (
              <ButtonBase
                className={classes.rightNavButton}
                onClick={() => props.nextSlide()}
                aria-label="next"
              >
                <KeyboardArrowRight
                  className={clsx(classes.navArrow, props.slidesToScroll)}
                />
              </ButtonBase>
            ) : null
          }}
        >
          {items && items.length > 0 && Array.isArray(items)
            ? items.map((item, index) => {
                return (
                  <div key={index} className={classes.itemWrapper}>
                    <UserEngageCard {...item} />
                  </div>
                )
              })
            : null}
        </Carousel>
      </div>
    </div>
  )
}

export default MapTo('cms-commons/components/content/userEngageCarousel')(
  UserEngageCarousel
)
