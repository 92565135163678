import React, { useState } from 'react'
import {
  sendSearchWithinEvent,
  sendFacetChangeEvent,
  sendCSRFiltersEvent,
} from '@utils/analytics'
import { makeStyles, Theme } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import messages from '@src/utils/messages'
import facetMessages from '@utils/MessageSets/defineFacetMessages'
import { useIntl } from 'react-intl'
import { useRouter } from '@src/routes'

const useStyles = makeStyles((theme: Theme) => ({
  Container: {
    display: 'flex',
    position: 'relative',
    width: '50vw',
    [theme.breakpoints.up('md')]: {
      width: '20vw',
    },
    maxWidth: '387px',
  },
  TextField: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    border: 'none',
    boxShadow: `inset 0 0 0 1px ${theme.palette.grey['500']}`,
    transition: 'all .3s',
    height: 32,
    boxSizing: 'border-box',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: '32px',
    padding: '0 30px 0 12px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      height: 40,
      lineHeight: '40px',
    },

    '&:hover:not($disabled)': {
      boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
    },

    '&$error': {
      boxShadow: `inset 0 0 0 2px ${theme.palette.error.main}`,
      '&:hover': { boxShadow: `inset 0 0 0 2px ${theme.palette.error.main}` },
      '&:focus': { boxShadow: `inset 0 0 0 2px ${theme.palette.error.main}` },
    },
  },
  searchIcon: {},
  Button: {
    position: 'absolute',
    right: 0,
    top: 1,
    width: 30,
    height: 38,
    padding: 0,
    paddingTop: 3,
    border: 0,
    background: 'none',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}))

interface CSRSearchFieldProps {
  searchTerm?: string
  handleUpdateSearchTerm: (searchTerm?: string) => void
}

const CSRSearchField: React.FC<CSRSearchFieldProps> = ({
  searchTerm,
  handleUpdateSearchTerm,
}) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const [term, setTerm] = useState(searchTerm)
  const router = useRouter()

  const facetsAndSearchTerm = (searchTerm, sendSelected) => {
    const keyword = `keyword: ${searchTerm}`
    const previousSelectedFacetsArray = router.query.facets
      ? router.query.facets.split(',').map((eachFacet) => {
          const [title, value] = eachFacet.split(':')
          const formatTitle =
            facetMessages[title.toUpperCase()].defaultMessage || title
          return `${formatTitle}: ${value}`
        })
      : []

    return sendSelected
      ? previousSelectedFacetsArray.length
        ? keyword + ' >> ' + previousSelectedFacetsArray.join(' > ')
        : keyword
      : previousSelectedFacetsArray.join(' | ')
  }

  const doSearch = (value?: string) => {
    handleUpdateSearchTerm(value ?? term)
    if (value || term) {
      sendSearchWithinEvent(value ?? term)
      const eventLabel = facetsAndSearchTerm(value ?? term, true)
      sendFacetChangeEvent(eventLabel, !(value ?? term))
      sendCSRFiltersEvent({
        event: 'filter_interaction',
        action: !(value ?? term) ? 'remove filter' : 'add filter',
        component: 'top rail',
        elementType: 'search box',
        coreEvent: 'no',
        filterName: value ?? term,
        filterExisting: facetsAndSearchTerm(value ?? term, false),
      })
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !!event.target.value) {
      setTerm(event.target.value)
      doSearch(event.target.value)
    }
  }

  const handleChange = (event) => {
    setTerm(event.target.value)
  }

  return (
    <div className={classes.Container}>
      <input
        className={classes.TextField}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={formatMessage(messages.FILTER_BY_KEYWORD)}
        value={term}
      />

      <button className={classes.Button} onClick={() => doSearch()}>
        <SearchIcon className={classes.searchIcon} />
      </button>
    </div>
  )
}

export default CSRSearchField
