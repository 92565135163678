import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { ApolloError } from 'apollo-boost'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
} from '@material-ui/core'
import { SubstanceProductFieldsFragment } from '@src/queries/ProductSearchQuery.generated'
import { sendSeeAllEvent } from '@utils/analytics'
import ShowMoreLessButton from '@src/components/ShowMoreLessButton'
import { AemAdditionalColumns } from '@src/aem-content/components/DynamicProductTable'
import Product from './Product'
import { TrackedAvailabilityMessageProvider } from '@src/components/TrackedAvailabilityMessage/TrackedAvailabilityMessageContext'

const useStyles = makeStyles((theme: Theme) => {
  return {
    tContainer: {
      overflowX: 'visible',
    },
    tHeadRow: {
      whiteSpace: 'nowrap',
      '& .MuiTableCell-head': {
        padding: '0 .6rem',
        fontSize: theme.typography.pxToRem(12),
        fontWeight: `${theme.typography.fontWeightBold} !important`,
      },
    },
    tHeadProductNumber: {
      width: 124,
    },
    tHeadProductName: {
      width: 290,
    },
    tHeadSds: {
      width: 52,
      '&:empty': {
        width: 0,
      },
    },
    hideBelowMd: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    utilityRow: {
      display: 'flex',
      marginTop: theme.spacing(4),
      '&:empty': {
        display: 'none',
      },
    },
    showMoreLessButton: {
      flexShrink: 0,
    },
    compressWidth: {
      width: 0,
    },
    aemThead: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  }
})

export const cardCategoryKeys = {
  ANTIBODY: 'ANTIBODY',
  SYRINGE_FILTERS: 'SYRINGE_FILTERS',
}

export const cardCategories = [
  cardCategoryKeys.ANTIBODY,
  cardCategoryKeys.SYRINGE_FILTERS,
]

const ProductHeaders = ({ formatMessage, additionalColumns, isShowBrand }) => {
  const classes = useStyles()
  return (
    <>
      <TableCell>
        {formatMessage({
          id: 'DESCRIPTION',
          defaultMessage: 'Product Description',
        })}
      </TableCell>
      {isShowBrand && (
        <TableCell>
          {formatMessage({ id: 'BRAND', defaultMessage: 'Brand' })}
        </TableCell>
      )}
      {additionalColumns &&
        additionalColumns.map(({ text }) => (
          <TableCell key={text} data-additional="true">
            {text}
          </TableCell>
        ))}

      <TableCell className={classes.compressWidth}>
        {formatMessage({ id: 'PRICING', defaultMessage: 'Pricing' })}
      </TableCell>
    </>
  )
}
export interface ProductTableProps {
  products: SubstanceProductFieldsFragment[]
  displayBadgesOnProduct: boolean
  focus: string
  error?: ApolloError
  maxRows?: number // aem prop
  isShowImage?: boolean // aem props
  isShowBrand?: boolean // aem props
  additionalColumns?: AemAdditionalColumns[] // aem props
  substanceName?: string
  displayViewLink?: boolean
}

const ProductTable: React.FC<ProductTableProps> = ({
  products,
  displayBadgesOnProduct,
  focus,
  maxRows,
  isShowImage = false,
  isShowBrand = false,
  additionalColumns,
  substanceName,
  displayViewLink,
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const limit = maxRows || 5
  const maxProductsToShow = expanded ? products.length : limit
  const { formatMessage } = useIntl()
  const productIsAntibody =
    !!products.length && products[0]?.cardCategory === cardCategoryKeys.ANTIBODY
  const productIsSyringeFilter =
    !!products.length &&
    products[0]?.cardCategory === cardCategoryKeys.SYRINGE_FILTERS

  if (expanded) {
    sendSeeAllEvent(substanceName, products.length)
  }
  return (
    <TableContainer className={classes.tContainer}>
      <Table size="small">
        <TableHead className={classes.aemThead}>
          <TableRow className={classes.tHeadRow}>
            {isShowImage ? <TableCell /> : null}
            <TableCell classes={{ root: classes.tHeadProductNumber }}>
              {formatMessage({
                id: 'PRODUCT_NO',
                defaultMessage: 'Product Number',
              })}
            </TableCell>
            <TableCell classes={{ root: classes.tHeadProductName }}>
              {formatMessage({
                id: 'PRODUCT_NAME',
                defaultMessage: 'Product Name',
              })}
            </TableCell>
            {!products[0]?.cardCategory && (
              <ProductHeaders
                formatMessage={formatMessage}
                additionalColumns={additionalColumns}
                isShowBrand={isShowBrand}
              />
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {products.slice(0, maxProductsToShow).map((product, index) =>
            product instanceof Error || !product ? null : (
              <TrackedAvailabilityMessageProvider
                source={`srp - ${focus}`}
                key={index}
              >
                <Product
                  key={`${product.productKey}${product.brand.erpKey}-${index}`}
                  product={product}
                  displayBadgesOnProduct={displayBadgesOnProduct}
                  focus={focus}
                  isShowBrand={isShowBrand}
                  isShowImage={isShowImage}
                  additionalColumns={additionalColumns}
                  productIsAntibody={productIsAntibody}
                  productIsSyringeFilter={productIsSyringeFilter}
                  displayViewLink={displayViewLink}
                />
              </TrackedAvailabilityMessageProvider>
            )
          )}
        </TableBody>
      </Table>
      <div className={classes.utilityRow}>
        {products.length > limit && (
          <ShowMoreLessButton
            expanded={expanded}
            setExpanded={setExpanded}
            className={classes.showMoreLessButton}
          />
        )}
      </div>
    </TableContainer>
  )
}

export default ProductTable
