/*
    Category Search Results
    Maps to cms-commons/components/content/categorysearchresults
*/

import React from 'react'
import { MapTo } from '@adobe/aem-react-editable-components'
import CategorySearchResults from './CategorySearchResults'
import { Sort } from '@src/types/graphql-types'

interface CategorySearchResultsAEMProps {
  searchTerm: string
  title?: string
  facets: string[]
  maxRows: number
  additionalColumns: AdditionalColumns[]
  anchorId?: string
  defaultSort?: string
  facetSet?: string[]
  hideSearchBar?: boolean
  isInEditor: boolean
  cqPath: string
  openFacets?: number
}

export interface AdditionalColumns {
  text: string
  value: string
}

const CategorySearchResultsAdapter: React.FC<CategorySearchResultsAEMProps> = ({
  anchorId,
  title,
  searchTerm,
  facets,
  maxRows,
  additionalColumns,
  defaultSort = [],
  facetSet,
  hideSearchBar = false,
  openFacets = 0,
}) => {
  const sort = Object.values(Sort).find((v) => v === defaultSort[0])
  return (
    <CategorySearchResults
      anchorId={anchorId}
      title={title}
      searchTerm={searchTerm}
      additionalColumns={additionalColumns}
      maxRows={maxRows}
      authorSelectedFacets={facets}
      defaultSort={sort}
      authorCuratedFacets={facetSet ? facetSet : []}
      hideSearchBar={hideSearchBar}
      openFacets={openFacets}
    />
  )
}

export default MapTo<CategorySearchResultsAEMProps>(
  'cms-commons/components/content/categorysearchresults'
)(CategorySearchResultsAdapter)
