import { AnalyticsDispType } from '@src/types/graphql-types'

const bestSellers = 'Best Sellers'
const newProducts = 'New Arrivals'
const popularProducts = 'Popular Products'

const appPageBestSellersCodesMap = {
  S002bestsellers: 'S002',
  S003bestsellers: 'S003',
  S007bestsellers: 'S007',
  S012bestsellers: 'S012',
  S015bestsellers: 'S015',
  S017bestsellers: 'S017',
  S020bestsellers: 'S020',
  S022bestsellers: 'S022',
  S029bestsellers: 'S029',
  S053bestsellers: 'S053',
  S064bestsellers: 'S064',
  S071bestsellers: 'S071',
  S103bestsellers: 'S103',
  S107bestsellers: 'S107',
  S111bestsellers: 'S111',
  S114bestsellers: 'S114',
  S125bestsellers: 'S125',
}

const appPageNewProductsCodesMap = {
  S002newProducts: 'S002',
  S003newProducts: 'S003',
  S007newProducts: 'S007',
  S012newProducts: 'S012',
  S015newProducts: 'S015',
  S017newProducts: 'S017',
  S020newProducts: 'S020',
  S022newProducts: 'S022',
  S029newProducts: 'S029',
  S053newProducts: 'S053',
  S064newProducts: 'S064',
  S071newProducts: 'S071',
  S103newProducts: 'S103',
  S107newProducts: 'S107',
  S111newProducts: 'S111',
  S114newProducts: 'S114',
  S125newProducts: 'S125',
}

const catPageBestSellersCodesMap = {
  S138: 'S138',
  S140: 'S140',
  S151: 'S151',
  S194: 'S194',
  S198: 'S198',
  S199: 'S199',
  S241: 'S241',
  S280: 'S280',
  S307: 'S307',
  S315: 'S315',
  S319: 'S319',
  S324: 'S324',
  S348: 'S348',
  S366: 'S366',
  S372: 'S372',
  S395: 'S395',
  S402: 'S402',
  S863: 'S863',
  CP007: 'Cp007',
}

const createProductRecommendationPayload = (
  typeId: string,
  disp: AnalyticsDispType,
  headline: string
) => ({
  typeId,
  disp,
  headline,
})

export const recommendationProductsConst = {
  bestsellers: createProductRecommendationPayload(
    'homePageBestSellersAPI',
    AnalyticsDispType.BestSellers,
    bestSellers
  ),
  buyAgain: createProductRecommendationPayload(
    'biaRBHMAPI',
    AnalyticsDispType.BiaSc,
    'Buy Again'
  ),
  newProducts: createProductRecommendationPayload(
    'newProductsAPI',
    AnalyticsDispType.NewProducts,
    newProducts
  ),
  popular_products: createProductRecommendationPayload(
    'homePagePopularProductsAPI',
    AnalyticsDispType.PopularProducts,
    popularProducts
  ),
}

for (const code in appPageBestSellersCodesMap) {
  recommendationProductsConst[code] = createProductRecommendationPayload(
    'appPageBestSellersAPI',
    AnalyticsDispType[appPageBestSellersCodesMap[code]],
    bestSellers
  )
}
for (const code in appPageNewProductsCodesMap) {
  recommendationProductsConst[code] = createProductRecommendationPayload(
    'appPageNewProductsAPI',
    AnalyticsDispType[appPageNewProductsCodesMap[code]],
    newProducts
  )
}
for (const code in catPageBestSellersCodesMap) {
  recommendationProductsConst[code] = createProductRecommendationPayload(
    'catPageBestSellersAPI',
    AnalyticsDispType[catPageBestSellersCodesMap[code]],
    bestSellers
  )
}
