import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { MapTo } from '@adobe/aem-react-editable-components'
import { useMetadataForFfCertificatesQuery } from '@src/queries/FFMetadataQuery.generated'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Head from 'next/head'
import { decodePageTitle } from '@src/utils/decodeTitle'
import { Link } from '@src/components/Link'
import { productDetailRoute } from '@src/routes'

interface DynamicPlaceholderProps {
  ffpdpaliases?: string[]
  ffpdpattributes?: string[]
  ffproductNameSuffix?: string
  ffproductNumber?: string
  ffproductId?: string
  title?: string
  ffproductName?: string
  showProductName?: string
  showProductNameSuffix?: string
  showProductNumber?: string
  ffPageTitle?: string
  ffPageDesc?: string
  isInEditor?: boolean
  cqPath?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  dynamicPlaceholderWrapper: {
    marginBottom: theme.spacing(6),
    '& $productInfoText': {
      '& span': {
        marginRight: theme.spacing(1),
      },
      '&:hover': {
        textDecoration: 'none',
      },
      '@media print': {
        display: 'none',
      },
    },
    '& $productInfoPrintText': {
      display: 'none',
      fontSize: theme.typography.pxToRem(20),
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
      fontWeight: '900',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      '& span': {
        marginRight: theme.spacing(1),
      },
      '@media print': {
        display: 'block',
      },
    },
  },
  dynamicPlaceholderDiv: {
    '& p': {
      marginBottom: theme.spacing(2),
      textTransform: 'capitalize',
    },
    '& strong': {
      marginRight: theme.spacing(1),
    },
  },
  productInfoText: {},
  productInfoPrintText: {},
}))

const DynamicPlaceholder = (props: DynamicPlaceholderProps) => {
  const router = useRouter()
  const classes = useStyles()
  const path = router.query
  const brandKey = path.cmsRoute[3] || ''
  const productKey = path.cmsRoute[4] || ''
  const {
    ffpdpaliases,
    ffpdpattributes,
    showProductName,
    showProductNumber,
    showProductNameSuffix,
    ffPageTitle = '',
    ffPageDesc = '',
  } = props
  const [aliases, setAliases] = useState([])
  const [attributes, setAttributes] = useState([])
  const [productNumber, setProductNumber] = useState<string>('')
  const [productName, setProductName] = useState<string>('')
  const [productNameSuffix, setProductNameSuffix] = useState<string>('')
  const { data } = useMetadataForFfCertificatesQuery({
    variables: {
      input: {
        productKey,
        brandKey,
      },
    },
    fetchPolicy: 'no-cache',
  })

  const displayDynamicFields = (field) => {
    if (field?.length) {
      return field.map((item: any) => {
        return (
          <p key={item.value}>
            <strong>{item.label}:</strong>
            {/* eslint-disable-next-line react/no-danger   */}
            <span dangerouslySetInnerHTML={{ __html: item.value }}></span>
          </p>
        )
      })
    }
    return null
  }

  const dynamicCertTitle = `${ffPageTitle} for ${brandKey} ${productNumber} ${productName} ${productNameSuffix}`
  const dynamicCertDescription = `${ffPageDesc} for ${brandKey} ${productNumber} ${productName} ${productNameSuffix}`

  useEffect(() => {
    const getDisplayFields = (displayField) => {
      const metadata: any = []
      if (displayField?.length) {
        displayField.forEach((item) => {
          const itemArr = item.split('@')
          const itemObj = {
            key: itemArr[0],
            sequence: itemArr[1],
          }
          metadata.push(itemObj)
        })
        return metadata
      }
      return []
    }

    const getFilteredDisplayFields = (dispFields, prodFields) => {
      return prodFields?.filter((prodField) =>
        dispFields?.some((dispField) => dispField.key === prodField?.key)
      )
    }

    if (data?.getMetadataForFFCertificates) {
      const {
        aliases,
        attributes,
        productNumber,
        productNameSuffix,
        productName,
      } = data.getMetadataForFFCertificates
      const displayAliases = getDisplayFields(ffpdpaliases)
      const displayAttributes = getDisplayFields(ffpdpattributes)
      const filteredAliases =
        (displayAliases.length &&
          getFilteredDisplayFields(displayAliases, aliases)) ||
        []
      const filteredAttributes =
        (displayAttributes.length &&
          getFilteredDisplayFields(displayAttributes, attributes)) ||
        []
      filteredAliases.length && setAliases(filteredAliases)
      filteredAttributes.length && setAttributes(filteredAttributes)
      productNumber && setProductNumber(productNumber)
      productName && setProductName(productName)
      productNameSuffix && setProductNameSuffix(productNameSuffix)
    }
  }, [data])

  return (
    <>
      <Head>
        <title>{decodePageTitle(dynamicCertTitle)}</title>
        <meta
          name="description"
          content={decodePageTitle(dynamicCertDescription)}
        />
      </Head>
      <div className={classes.dynamicPlaceholderWrapper}>
        <Link {...productDetailRoute.index(brandKey, productNumber)}>
          <a className={classes.productInfoText}>
            {showProductNumber && (
              // eslint-disable-next-line react/no-danger
              <span dangerouslySetInnerHTML={{ __html: productNumber }}></span>
            )}
            {showProductName && (
              // eslint-disable-next-line react/no-danger
              <span dangerouslySetInnerHTML={{ __html: productName }}></span>
            )}
            {showProductNameSuffix && (
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: productNameSuffix }}
              ></span>
            )}
          </a>
        </Link>
        <p className={classes.productInfoPrintText}>
          {showProductNumber && (
            // eslint-disable-next-line react/no-danger
            <span dangerouslySetInnerHTML={{ __html: productNumber }}></span>
          )}
          {showProductName && (
            // eslint-disable-next-line react/no-danger
            <span dangerouslySetInnerHTML={{ __html: productName }}></span>
          )}
          {showProductNameSuffix && (
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: productNameSuffix }}
            ></span>
          )}
        </p>
        <div className={classes.dynamicPlaceholderDiv}>
          {displayDynamicFields(aliases)}
          {displayDynamicFields(attributes)}
        </div>
      </div>
    </>
  )
}

export default MapTo('cms-commons/components/content/dynamicplaceholder')(
  DynamicPlaceholder
)
