import Cookies from 'universal-cookie'
import { EventValues, GTMEventTypes } from '@sial/common-utils'
import { sendEvent } from '@utils/analytics/coreAnalytics'

export const sendGatedContentEvent = (eventAction: 'sign in' | 'register') => {
  const cookies = new Cookies()
  const gaCookie = cookies.get('_ga') || EventValues.Empty
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: 'gated content',
      eventAction,
      eventLabel: gaCookie,
      eventInteractionType: 0,
    },
  })
}
