import React from 'react'
import { CategoryProductSearchFieldsFragment } from '@src/queries/CategoryProductSearchQuery.generated'
import CSRGridProductCard from './CSRGridProductCard'
import CompareDrawer from '@src/routes/SearchResults/CompareDrawer'
import { Grid } from '@material-ui/core'

interface CSRGridProductListProps {
  products: CategoryProductSearchFieldsFragment['items']
}
const CSRGridProductList: React.FC<CSRGridProductListProps> = ({
  products,
}) => {
  return (
    <Grid container spacing={10}>
      {products.map((product) => {
        if (product.__typename !== 'Product') return null
        return (
          <CSRGridProductCard key={product.productNumber} product={product} />
        )
      })}
      <CompareDrawer isAemDrawer />
    </Grid>
  )
}

export default CSRGridProductList
