export const states = {
  AA: 'Armed Forces Americas',
  AE: 'Armed Forces Europe',
  AK: 'tfa_2822',
  AL: 'tfa_2821',
  AP: 'Armed Forces Pacific',
  AR: 'tfa_2824',
  AS: 'tfa_2876',
  AZ: 'tfa_2823',
  CA: 'tfa_2825',
  CO: 'tfa_2826',
  CT: 'tfa_2827',
  DC: 'tfa_2829',
  DE: 'tfa_2828',
  FL: 'tfa_2830',
  FM: 'Fed States of Micronesia',
  GA: 'tfa_2831',
  GU: 'tfa_2875',
  HI: 'tfa_2832',
  IA: 'tfa_2836',
  ID: 'tfa_2833',
  IL: 'tfa_2834',
  IN: 'tfa_2835',
  KS: 'tfa_2837',
  KY: 'tfa_2838',
  LA: 'tfa_2839',
  MA: 'tfa_2842',
  MD: 'tfa_2841',
  ME: 'tfa_2840',
  MH: 'Marshall Islands',
  MI: 'tfa_2843',
  MN: 'tfa_2844',
  MO: 'tfa_2846',
  MP: 'tfa_2874',
  MS: 'tfa_2845',
  MT: 'tfa_2847',
  NC: 'tfa_2854',
  ND: 'tfa_2855',
  NE: 'tfa_2848',
  NH: 'tfa_2850',
  NJ: 'tfa_2851',
  NM: 'tfa_2852',
  NV: 'tfa_2849',
  NY: 'tfa_2853',
  OH: 'tfa_2856',
  OK: 'tfa_2857',
  OR: 'tfa_2858',
  PA: 'tfa_2859',
  PR: 'tfa_2872',
  PW: 'tfa_2877',
  RI: 'tfa_2860',
  SC: 'tfa_2861',
  SD: 'tfa_2862',
  TN: 'tfa_2863',
  TX: 'tfa_2864',
  UT: 'tfa_2865',
  VA: 'tfa_2867',
  VI: 'tfa_2873',
  VT: 'tfa_2866',
  WA: 'tfa_2868',
  WI: 'tfa_2870',
  WV: 'tfa_2869',
  WY: 'tfa_2871',
  // 'AA': 'Armed Forces Americas',
  // 'AE': 'Armed Forces Europe',
  // 'AP': 'Armed Forces Pacific',
  // 'FM': 'Fed States of Micronesia',
  // 'MH': 'Marshall Islands',
}

export const countries = {
  AD: 'tfa_2587',
  AE: 'tfa_2804',
  AF: 'tfa_2583',
  AG: 'tfa_2591',
  AI: 'tfa_2589',
  AL: 'tfa_2584',
  AM: 'tfa_2593',
  AN: 'tfa_2733',
  AO: 'tfa_2588',
  AR: 'tfa_2592',
  AS: 'tfa_2586',
  AT: 'tfa_2596',
  AU: 'tfa_2595',
  AW: 'tfa_2594',
  AZ: 'tfa_2597',
  BA: 'tfa_2609',
  BB: 'tfa_2601',
  BD: 'tfa_2600',
  BE: 'tfa_2603',
  BF: 'tfa_2616',
  BG: 'tfa_2615',
  BH: 'tfa_2599',
  BI: 'tfa_2617',
  BJ: 'tfa_2605',
  BM: 'tfa_2606',
  BN: 'tfa_2614',
  BO: 'tfa_2608',
  BR: 'tfa_2612',
  BS: 'tfa_2598',
  BT: 'tfa_2607',
  BV: 'tfa_2611',
  BW: 'tfa_2610',
  BY: 'tfa_2602',
  BZ: 'tfa_2604',
  CA: 'tfa_2620',
  CD: 'tfa_2632',
  CF: 'tfa_2623',
  CG: 'tfa_2631',
  CH: 'tfa_2787',
  CI: 'tfa_2635',
  CK: 'tfa_2633',
  CL: 'tfa_2625',
  CM: 'tfa_2619',
  CN: 'tfa_2626',
  CO: 'tfa_2629',
  CR: 'tfa_2634',
  CV: 'tfa_2621',
  CX: 'tfa_2627',
  CY: 'tfa_2638',
  CZ: 'tfa_2639',
  DE: 'tfa_2662',
  DJ: 'tfa_2641',
  DK: 'tfa_2640',
  DM: 'tfa_2642',
  DO: 'tfa_2643',
  DZ: 'tfa_2585',
  EC: 'tfa_2644',
  EE: 'tfa_2649',
  EG: 'tfa_2645',
  ER: 'tfa_2648',
  ES: 'tfa_2780',
  ET: 'tfa_2650',
  FI: 'tfa_2654',
  FJ: 'tfa_2653',
  FK: 'tfa_2651',
  FM: 'tfa_2720',
  FO: 'tfa_2652',
  FR: 'tfa_2655',
  GA: 'tfa_2659',
  GB: 'tfa_2805',
  GD: 'tfa_2667',
  GE: 'tfa_2661',
  GF: 'tfa_2656',
  GH: 'tfa_2663',
  GI: 'tfa_2664',
  GL: 'tfa_2666',
  GM: 'tfa_2660',
  GN: 'tfa_2671',
  GP: 'tfa_2668',
  GQ: 'tfa_2647',
  GR: 'tfa_2665',
  GT: 'tfa_2670',
  GU: 'tfa_2669',
  GW: 'tfa_2672',
  GY: 'tfa_2673',
  HK: 'tfa_2678',
  HM: 'tfa_2675',
  HN: 'tfa_2677',
  HR: 'tfa_2636',
  HT: 'tfa_2674',
  HU: 'tfa_2679',
  ID: 'tfa_2682',
  IE: 'tfa_2685',
  IL: 'tfa_2686',
  IN: 'tfa_2681',
  IQ: 'tfa_2684',
  IS: 'tfa_2680',
  IT: 'tfa_2687',
  JM: 'tfa_2688',
  JO: 'tfa_2690',
  JP: 'tfa_2689',
  KE: 'tfa_2692',
  KG: 'tfa_2696',
  KH: 'tfa_2618',
  KI: 'tfa_2693',
  KM: 'tfa_2630',
  KN: 'tfa_2761',
  KR: 'tfa_2694',
  KW: 'tfa_2695',
  KY: 'tfa_2622',
  KZ: 'tfa_2691',
  LA: 'tfa_2697',
  LB: 'tfa_2699',
  LC: 'tfa_2762',
  LI: 'tfa_2703',
  LK: 'tfa_2781',
  LR: 'tfa_2701',
  LS: 'tfa_2700',
  LT: 'tfa_2704',
  LU: 'tfa_2705',
  LV: 'tfa_2698',
  LY: 'tfa_2702',
  MA: 'tfa_2726',
  MC: 'tfa_2722',
  MD: 'tfa_2721',
  ME: 'tfa_2724',
  MG: 'tfa_2708',
  MH: 'tfa_2714',
  MK: 'tfa_2707',
  ML: 'tfa_2712',
  MM: 'tfa_2728',
  MN: 'tfa_2723',
  MO: 'tfa_2706',
  MP: 'tfa_2741',
  MQ: 'tfa_2715',
  MR: 'tfa_2716',
  MS: 'tfa_2725',
  MT: 'tfa_2713',
  MU: 'tfa_2717',
  MV: 'tfa_2711',
  MW: 'tfa_2709',
  MX: 'tfa_2719',
  MY: 'tfa_2710',
  MZ: 'tfa_2727',
  NA: 'tfa_2729',
  NC: 'tfa_2734',
  NE: 'tfa_2737',
  NF: 'tfa_2740',
  NG: 'tfa_2738',
  NI: 'tfa_2736',
  NL: 'tfa_2732',
  NO: 'tfa_2742',
  NP: 'tfa_2731',
  NR: 'tfa_2730',
  NU: 'tfa_2739',
  NZ: 'tfa_2735',
  OM: 'tfa_2743',
  PA: 'tfa_2746',
  PE: 'tfa_2749',
  PF: 'tfa_2657',
  PG: 'tfa_2747',
  PH: 'tfa_2750',
  PK: 'tfa_2744',
  PL: 'tfa_2752',
  PM: 'tfa_2763',
  PN: 'tfa_2751',
  PT: 'tfa_2753',
  PW: 'tfa_2745',
  PY: 'tfa_2748',
  QA: 'tfa_2755',
  RE: 'tfa_2756',
  RO: 'tfa_2757',
  RS: 'tfa_2770',
  RU: 'tfa_2758',
  RW: 'tfa_2759',
  SA: 'tfa_2768',
  SB: 'tfa_2776',
  SC: 'tfa_2771',
  SD: 'tfa_2782',
  SE: 'tfa_2786',
  SG: 'tfa_2773',
  SH: 'tfa_2760',
  SI: 'tfa_2775',
  SK: 'tfa_2774',
  SL: 'tfa_2772',
  SM: 'tfa_2766',
  SN: 'tfa_2769',
  SO: 'tfa_2777',
  SR: 'tfa_2783',
  ST: 'tfa_2767',
  SV: 'tfa_2646',
  SZ: 'tfa_2785',
  TC: 'tfa_2800',
  TD: 'tfa_2624',
  TG: 'tfa_2793',
  TH: 'tfa_2792',
  TJ: 'tfa_2790',
  TK: 'tfa_2794',
  TM: 'tfa_2799',
  TN: 'tfa_2797',
  TO: 'tfa_2795',
  TP: 'East Timor',
  TR: 'tfa_2798',
  TT: 'tfa_2796',
  TV: 'tfa_2801',
  TW: 'tfa_2789',
  TZ: 'tfa_2791',
  UA: 'tfa_2803',
  UG: 'tfa_2802',
  US: 'tfa_2806',
  UY: 'tfa_2808',
  UZ: 'tfa_2809',
  VA: 'tfa_2676',
  VC: 'tfa_2764',
  VE: 'tfa_2811',
  VG: 'tfa_2813',
  VI: 'tfa_2814',
  VN: 'tfa_2812',
  VU: 'tfa_2810',
  WF: 'tfa_2815',
  WS: 'tfa_2765',
  YE: 'tfa_2816',
  YT: 'tfa_2718',
  YU: 'Yugoslavia',
  ZA: 'tfa_2778',
  ZM: 'tfa_2817',
  ZR: 'Zaire',
  ZW: 'tfa_2818',
  // 'TP': 'East Timor',
  // 'YU': 'Yugoslavia',
  // 'ZR': 'Zaire',
}
