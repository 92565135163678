import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { FormattedMessage, useIntl } from 'react-intl'
import { ButtonBase } from '@material-ui/core'
import { Facet } from '@src/types/graphql-types'
import { FacetSelection } from './useCategoryProductSearch'
import XIcon from '@src/icons/XIcon'
import { flatMap } from 'lodash'
import HandleMarkup from '@src/components/HandleMarkup'
import clsx from 'clsx'
import { useRouter } from '@src/routes'
import { sendCSRFiltersEvent } from '@utils/analytics'
import facetMessages from '@utils/MessageSets/defineFacetMessages'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: theme.spacing(6),
    marginBottom: theme.spacing(6),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  selectedContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  selectedText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  clearAll: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    cursor: 'pointer',
  },
  selectedChipWrapper: {
    display: 'inline-block',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.primary,
    background: theme.palette.primary.light,
    padding: theme.spacing(2),
    borderRadius: '3px',
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 1.5),
    },
  },
  closeBtn: {
    marginLeft: theme.spacing(2),
    marginRight: '0 !important',
    fontSize: theme.typography.pxToRem(10),
  },
  alignmentWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  facetMarkup: {
    marginRight: theme.spacing(1),
  },
  hideForMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

interface CSRSelectedFacetsProps {
  facets?: Facet[]
  term?: string
  handleUpdateFacets: (facet: FacetSelection | {}, clearAll?: boolean) => void
  handleUpdateSearchTerm?: (searchTerm?: string) => void
}

const CSRSelectedFacets: React.FC<CSRSelectedFacetsProps> = ({
  facets,
  term,
  handleUpdateFacets,
  handleUpdateSearchTerm,
}) => {
  const classes = useStyles()
  const router = useRouter()
  const { formatMessage } = useIntl()
  const selectedText = formatMessage({
    id: 'YOU_SELECTED',
    defaultMessage: "You've Selected",
  })

  const facetsAndSearchTerm = (existingFilters) => {
    const previousSelectedFacetsArray = existingFilters
      ? existingFilters.map((eachFacet) => {
          const formatTitle =
            facetMessages[eachFacet.key.toUpperCase()].defaultMessage ||
            eachFacet.key
          return `${formatTitle}: ${eachFacet.label}`
        })
      : []
    const searchTerm = router.query.term || router.query.searched
    const keyword = searchTerm ? `keyword: ${searchTerm}` : ''
    const previousFacetsString = previousSelectedFacetsArray.length
      ? previousSelectedFacetsArray.join(' | ')
      : ''

    // When no data is available return explicitly undefined.
    if (!keyword && !previousFacetsString) {
      return undefined
    }

    // Render keyword
    return `${keyword} ${
      keyword && previousFacetsString ? ' | ' : ''
    } ${previousFacetsString}`
  }

  const mappedSelectedFacets = flatMap(facets, (facet) =>
    facet.options
      // @ts-ignore
      .filter((option) => !!option.selected)
      .map((option) => ({
        key: facet.key,
        label: option.value,
        prefix: facet.prefix,
        count: option.count,
      }))
  )
  const isFacetChipsShown = facets && mappedSelectedFacets.length > 0
  const selectedCount = term?.length
    ? mappedSelectedFacets.length + 1
    : mappedSelectedFacets.length

  if (!isFacetChipsShown && !term?.length) return null
  return (
    <div className={clsx(classes.container, classes.hideForMobile)}>
      <div className={classes.selectedContainer}>
        <div className={classes.selectedText}>
          {`${selectedText} (${selectedCount})`}
        </div>
        <div
          className={classes.clearAll}
          onClick={() => {
            handleUpdateFacets({}, true)
          }}
          role="button"
          tabIndex={0}
          onKeyDown={() => handleUpdateFacets({}, true)}
        >
          <FormattedMessage id="CLEAR_ALL" defaultMessage="Clear All" />
        </div>
      </div>
      {isFacetChipsShown &&
        mappedSelectedFacets.map((facet) => (
          <div
            className={classes.selectedChipWrapper}
            key={`${facet.key}:${facet.label}`}
          >
            <div className={classes.alignmentWrapper}>
              <span>
                <HandleMarkup value={facet.label} />
              </span>
              <ButtonBase
                className={classes.closeBtn}
                onClick={() => {
                  handleUpdateFacets(
                    {
                      key: facet.key,
                      value: facet.label,
                      prefix: facet.prefix,
                    },
                    false
                  )

                  const existingFilters = mappedSelectedFacets.filter(
                    (existingFilter) => existingFilter !== facet
                  )
                  sendCSRFiltersEvent({
                    event: 'filter_interaction',
                    action: 'remove filter',
                    component: 'left rail',
                    elementType: 'icon',
                    elementText: `${facet.label} (${facet.count})`,
                    coreEvent: 'no',
                    filterName: facet.label,
                    filterNameCount: `${facet.count}`,
                    filterCategory:
                      facetMessages[facet.key.toUpperCase()].defaultMessage ||
                      facet.key,
                    filterExisting: facetsAndSearchTerm(existingFilters),
                  })
                }}
              >
                <XIcon fontSize="inherit" color="primary" />
              </ButtonBase>
            </div>
          </div>
        ))}
      {term?.length && (
        <div className={classes.selectedChipWrapper}>
          <div className={classes.alignmentWrapper}>
            <span>
              <HandleMarkup value={term} />
            </span>
            <ButtonBase
              className={classes.closeBtn}
              onClick={() => {
                handleUpdateSearchTerm && handleUpdateSearchTerm('')
              }}
            >
              <XIcon fontSize="inherit" color="primary" />
            </ButtonBase>
          </div>
        </div>
      )}
    </div>
  )
}

export default CSRSelectedFacets
