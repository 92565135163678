import React from 'react'
import { useIntl } from 'react-intl'
import { MapTo } from '@adobe/aem-react-editable-components'
import aemMessages from '@src/aem-content/messages'
import EventSidebar from '@sial/aem-cms-components/dist/EventSidebar'

const EventSidebarAdapter: React.FC<any> = (props) => {
  const intl = useIntl()
  const online = intl.formatMessage(aemMessages['EVENT_ONLINE_LABEL'])
  const booth = intl.formatMessage(aemMessages['EVENT_BOOTH_LABEL'])

  const translations = {
    online,
    booth,
  }

  return <EventSidebar {...props} translations={translations} />
}

export default MapTo('cms-commons/components/content/event-sidebar')(
  EventSidebarAdapter
)
