import React from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  count: {
    fontSize: theme.typography.pxToRem(14),
  },
  bottomCount: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[800],
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
}))

interface CSRResultCountProps {
  itemCount: number
  pageStart: number
  pageEnd: number
  isBottomCount?: boolean
}

const CSRResultCount: React.FC<CSRResultCountProps> = ({
  pageStart,
  pageEnd,
  itemCount,
  isBottomCount = false,
}) => {
  const classes = useStyles()

  return isBottomCount ? (
    <div className={classes.bottomCount}>
      <FormattedMessage
        id="PAGINATION_RESULTS_VIEWED_TEXT"
        defaultMessage="You have viewed {pageStart}-{pageEnd} of {itemCount} results"
        values={{ pageStart, pageEnd, itemCount }}
      />
    </div>
  ) : (
    <div className={classes.count}>
      <FormattedMessage
        id="PAGINATION_RESULTS_SHOWING_TEXT"
        defaultMessage="Showing {pageStart}-{pageEnd} of {itemCount}"
        values={{ pageStart, pageEnd, itemCount }}
      />
    </div>
  )
}

export default CSRResultCount
