import React from 'react'
import { Button } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { Message } from '@src/utils/messages'

export interface BaseLicenseSectionProps {
  classes: any
  validationSchema: any
  intl: any
  setQueryResult: any
  heading: Message
  buttonText: Message
  buttonIdentifier: string
  href: string
}

export const BaseLicenseSection: React.FC<BaseLicenseSectionProps> = ({
  classes,
  heading,
  buttonText,
  buttonIdentifier,
  href,
}) => {
  return (
    <div className={classes.subSection}>
      <p className={classes.promptMessage}>
        <FormattedMessage {...heading} />
      </p>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        data-testid={buttonIdentifier}
        id={buttonIdentifier}
        className={classes.baseLicenseButton}
        href={href}
      >
        <FormattedMessage {...buttonText} />
      </Button>
    </div>
  )
}

export default BaseLicenseSection
