/*
    Dedicated file to include all React components that map to an AEM component
*/
import AemComponents from '@sial/aem-cms-components'
import CategorySearchResults from './CategorySearchResults'
import Contact from './Contact'
import Container from './Container'
import DynamicPlaceholder from './DynamicPlaceholder'
import DynamicProductTable from './DynamicProductTable'
import EventListing from './EventListing'
import EventSidebar from './EventSidebar'
import FFCertsBrandImage from './FFCertsBrandImage'
import FormAssembly from './FormAssembly'
import LocationMap from './LocationMap'
import Page from './Page'
import PressReleaseGrid from './PressReleaseGrid'
import PressReleaseListing from './PressReleaseListing'
import RelatedProductResources from './RelatedProductResources'
import RelatedWebinar from './RelatedWebinar'
import TrialLicense from './TrialLicense'
import UpcomingEventsGrid from './UpcomingEventsGrid'
import WebinarListing from './WebinarListing'
import WebinarSidebar from './WebinarSidebar'
import RelatedProducts from './RelatedProducts'
import ProductRecommendationCarousel from './ProductRecommendationCarousel'
import TextBanner from './TextBanner'
import HorizontalTab from './HorizontalTab'
import SiteMap from './SiteMap'
import UserEngageCarousel from './UserEngageCarousel'
import ProductSelector from './ProductSelector'
import GenericPlaceholder from './GenericPlaceholder'

const Components = {
  ...AemComponents,
  CategorySearchResults,
  Contact,
  DynamicProductTable,
  DynamicPlaceholder,
  FFCertsBrandImage,
  TextBanner,
  FormAssembly,
  TrialLicense,
  Container,
  EventListing,
  EventSidebar,
  LocationMap,
  PressReleaseGrid,
  PressReleaseListing,
  RelatedProductResources,
  RelatedWebinar,
  UpcomingEventsGrid,
  WebinarListing,
  WebinarSidebar,
  RelatedProducts,
  ProductRecommendationCarousel,
  Page,
  HorizontalTab,
  SiteMap,
  UserEngageCarousel,
  ProductSelector,
  GenericPlaceholder,
}
export { Components, Page }
