import React from 'react'
import { useRouter } from '@src/routes'
import { determineCatalogType } from '@utils/searchUtils'
import { useCurrentUser, SitePreference } from '@utils/useCurrentUser'
import { deObfuscateCatalogID, ObfuscatedCatalogID } from '@utils/catalogKeyIds'
import { useProductDetailQuery } from '@src/queries/ProductDetailQuery.generated'
import ProductQuickLook from '@src/components/ProductQuickLook/ProductQuickLook'
import { isServer } from '@src/utils/isServer'

const isTest = process.env.NODE_ENV === 'test'

interface DynamicProductModalProps {
  productId: string
  brand: string
  promotionalCode?: string
  closeModal: () => void
}

const DynamicProductModal: React.FC<DynamicProductModalProps> = ({
  productId,
  brand,
  promotionalCode,
  closeModal,
}) => {
  const router = useRouter()
  const { getSitePreference } = useCurrentUser()
  const orgId = getSitePreference(SitePreference.CatalogFilter) || undefined
  /* Context is obfuscated catlog id */
  const { context = '' } = router.query
  const catalog = deObfuscateCatalogID(context as ObfuscatedCatalogID)
  const catalogType = determineCatalogType(catalog)
  const clientFetchPolicy = !isServer() ? 'cache-first' : 'network-only'
  // @TODO this needs to be removed once testing or cache ids are added to products/materials
  const fetchPolicy = isTest ? 'no-cache' : clientFetchPolicy

  const { data } = useProductDetailQuery({
    variables: {
      productKey: productId,
      brandKey: brand,
      catalogType,
      orgId,
    },
    fetchPolicy,
  })

  return data?.getProductDetail ? (
    <ProductQuickLook
      onClose={closeModal}
      open
      image={data.getProductDetail.images[0]}
      brand={data.getProductDetail.brand}
      productKey={data.getProductDetail.productKey}
      productNumber={data.getProductDetail.productNumber}
      name={data.getProductDetail.name}
      description={data.getProductDetail.description}
      promotionalCode={promotionalCode}
    />
  ) : null
}

export default DynamicProductModal
