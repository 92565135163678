import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import getConfig from 'next/config'
import DOMPurify from 'isomorphic-dompurify'
import { log } from '@utils/logging'
import { useCompanyName } from '@utils/useCompanyName'
import { getLogoByBrandAndRegion } from '@utils/logoResolver'

interface Props {
  schemas: object[]
}

export const AemSeoSchema: React.FC<Props> = ({ schemas = [] }) => {
  let transformedSchemas = []
  const companyName = useCompanyName()
  const { asPath } = useRouter()
  const {
    publicRuntimeConfig: { canonicalUrlPrefix },
  } = getConfig()

  const replaceList = {
    '%%LOGO_ASSET_URL%%': getLogoByBrandAndRegion(companyName),
    '%%COMPANY_NAME%%': companyName,
    '%%CURRENT_BROWSER_URL%%': `${canonicalUrlPrefix}${asPath}`,
    '%%HOMEPAGE_URL%%': `${canonicalUrlPrefix}`,
  }

  try {
    let stringifiedSchema = JSON.stringify(schemas)

    for (const key in replaceList) {
      stringifiedSchema = stringifiedSchema.replace(
        new RegExp(key, 'g'),
        replaceList[key]
      )
    }

    transformedSchemas = JSON.parse(stringifiedSchema)
  } catch (error) {
    log.error(error, 'parse schema Error')
  }

  return (
    <Head>
      {transformedSchemas.map((schema, key) => {
        return (
          <script
            key={`schema${key}`}
            type="application/ld+json"
            // ONLY TO BE DISABLED IF USING a) HandleMarkup or b) in a script tag
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(JSON.stringify(schema)),
            }}
          />
        )
      })}
    </Head>
  )
}
