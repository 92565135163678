import { useRouter } from 'next/router'
import { parseRegionalUrl } from '@utils/regional'
import { useUserSession } from '@utils/useUserSession'
import { useCountriesData } from '@utils/useCountriesData'
import { useState } from 'react'
import { useSessionStorage } from 'react-storage'
import { AbTestingSessionStorageCarouselDataType } from '@src/components/DynamicProductCarousel/DynamicProductCarouselRecommendedAlt'
import { ProductCardType } from '@utils/searchUtils'

// UIPT-216
// This will be used for category & application AEM pages.
const useAemStaticContentOptlyCarousel = (sessionStorageName: string) => {
  const router = useRouter()
  const path = parseRegionalUrl(router.asPath).afterBasename?.split('?')[0]
  const { userSession } = useUserSession()
  const { getCountryProp } = useCountriesData()

  // Check for session data for AEM page tests
  const [sessionStorageData] = useState(
    useSessionStorage<AbTestingSessionStorageCarouselDataType>(
      sessionStorageName
    )
  )

  if (!sessionStorageData) {
    const model = '0'
    return { model }
  }

  const carouselEndpoint = sessionStorageData?.type

  const carouselTitle =
    carouselEndpoint === 'catPageBestSellersAPI'
      ? ProductCardType.BestSellers
      : ProductCardType.NewArrivals

  function getPageCode(path: string) {
    // eslint-disable-next-line sonarjs/max-switch-cases
    switch (path) {
      case '/products/industrial-microbiology/microbial-identification-tests':
        return 'S863'
      case '/products/analytical-chemistry/analytical-chromatography/hplc-columns':
        return 'S151'
      case '/products/labware/cell-culture-and-cryogenics/millicell-cell-culture-inserts-and-plates':
        return 'S372'
      case '/products/filtration/laboratory-syringe-filters/millex-syringe-filters':
        return 'S366'
      case '/products/cell-culture-and-analysis/cell-culture-supplements-and-reagents/cell-freezing-reagents':
        return 'S315'
      case '/products/cell-culture-and-analysis/cell-culture-supplements-and-reagents/fbs':
        return 'S324'
      case '/products/materials-science/biomedical-materials/3d-bioprinting':
        return 'S307'
      case '/products/industrial-microbiology/microbial-culture-media':
        return 'S194'
      case '/products/molecular-biology-and-functional-genomics/roche-life-science-products':
        return 'S348'
      case '/products/protein-biology/protein-sample-prep/flag-purification':
        return 'S402'
      case '/products/chemistry-and-biochemicals/biochemicals/metabolism-assay-kits':
        return 'S241'
      case '/products/labware/labware-partners/cytiva':
        return 'S395'
      case '/products/materials-science/energy-materials/high-purity-salts':
        return 'S280'
      case '/products/pharma-and-biopharma-manufacturing/formulation/liquid-formulation':
        return 'S138'
      case '/products/pharma-and-biopharma-manufacturing/formulation/biomolecule-formulation':
        return 'S140'
      case '/products/pharma-and-biopharma-manufacturing/formulation/injectables-parenteral-formulations':
        return 'CP007'
      case '/products/cell-culture-and-analysis/cell-culture-supplements-and-reagents/albumins-and-transport-proteins':
        return 'S319'
      case '/products/industrial-microbiology/microbial-filtration':
        return 'S198'
      case '/products/industrial-microbiology/sterility-testing-consumables-media-and-filtration':
        return 'S199'
      default:
        return '0'
    }
  }

  const regionCode: string = getCountryProp(userSession.country, 'regionCode')

  const regions: { [key: string]: string } = {
    AP: 'APAC',
    EU: 'EMEA',
    AF: 'EMEA',
    SA: 'LATAM',
  }

  // The catPageBestSellersAPI requires we add a regin code to the page code. IE: S151_NA
  const data = getPageCode(path || '0')
  if (data === '0' || carouselEndpoint === 'catPageNewProductsAPI') {
    const model = data
    return { model, carouselTitle, carouselEndpoint }
  }
  const region = regions[regionCode] || regionCode

  const model = `${data}_${region}`

  return { model, carouselTitle, carouselEndpoint }
}

export default useAemStaticContentOptlyCarousel
