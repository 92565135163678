import React, { useState, useEffect } from 'react'
import DynamicProductModal from '@src/components/DynamicProductModal'

export interface ProductInfo {
  productId: string
  brand: string
  promotionalCode?: string
}

const ProductModalEventListener = () => {
  const [productInfo, setProductInfo] = useState<ProductInfo | null>(null)

  const handlePageClick = (e) => {
    const button = e.target.closest('a')
    const link = button?.getAttribute('href')

    // only links that include string 'modal-product-info' will open as a modal
    if (!link?.includes('modal-product-info')) return
    e.preventDefault()

    const [, params] = link.split('?')
    const searchParams = new URLSearchParams(params)

    const productId = searchParams.get('productId')
    const brand = searchParams.get('brand')
    const promo = searchParams.get('promo')

    // Mandatory parameters to get the product details and open the modal
    if (!productId || !brand) return

    setProductInfo({
      productId,
      brand,
      promotionalCode: promo ? promo : '',
    })
  }

  useEffect(() => {
    document.addEventListener('click', handlePageClick)

    return () => {
      document.removeEventListener('click', handlePageClick)
    }
  }, [])

  return productInfo ? (
    <DynamicProductModal
      productId={productInfo.productId}
      brand={productInfo.brand}
      promotionalCode={productInfo.promotionalCode}
      closeModal={() => setProductInfo(null)}
    />
  ) : null
}

export default ProductModalEventListener
