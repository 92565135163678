import { useState, useEffect } from 'react'
import { CategoryProductSearchFieldsFragment } from '@src/queries/CategoryProductSearchQuery.generated'
import { QueryParamKeys } from './types'
import { DEFAULT_SEARCH_VALUES } from './useCategoryProductSearchQueryParams'

export const DEFAULT_SEARCH_RESULTS: CategoryProductSearchFieldsFragment = {
  items: [],
  facets: [],
  metadata: {
    itemCount: 0,
    page: DEFAULT_SEARCH_VALUES[QueryParamKeys.Page],
    perPage: DEFAULT_SEARCH_VALUES[QueryParamKeys.PerPage],
    numPages: 0,
  },
}

const useCacheCategoryProductSearch = (
  searchResults: CategoryProductSearchFieldsFragment,
  loading: boolean
) => {
  const [cache, setCache] = useState<CategoryProductSearchFieldsFragment>(
    DEFAULT_SEARCH_RESULTS
  )
  useEffect(() => {
    if (!loading && searchResults !== DEFAULT_SEARCH_RESULTS) {
      setCache(searchResults)
    }
  }, [loading, searchResults])

  return cache
}

export default useCacheCategoryProductSearch
