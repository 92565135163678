import { useCategoryProductSearchQuery } from '@src/queries/CategoryProductSearchQuery.generated'
import { Sort, Facet, FacetOptions } from '@src/types/graphql-types'
import { SelectedFacetState } from './types'
import useCategoryProductSearchQueryParams from './useCategoryProductSearchQueryParams'
import useCacheCategoryProductSearch, {
  DEFAULT_SEARCH_RESULTS,
} from './useCacheCategoryProductSearch'

const DELIMITERS = {
  AUTHORED_FACET_OPTIONS: ':',
  PREFIX: '/',
}

export interface FacetSelection {
  key: string
  value: string
  prefix?: string | null
}

export interface SelectableFacetOptions extends FacetOptions {
  selected: boolean
}

export interface SelectableFacet extends Facet {
  options: SelectableFacetOptions[]
}

interface CategoryProductSearch {
  authorSelectedFacets: string[]
  authorCuratedFacets?: string[]
  defaultSort?: Sort
  defaultPerPage?: number
  searchTerm?: string
  searchedFor?: string
}

const getAllSelectedFacets = (
  authorSelectedFacets,
  userSelectedFacets: SelectedFacetState
) => {
  const authorSelectedFacetMap = authorSelectedFacets.reduce((map, facet) => {
    const [key, value] = facet.split(DELIMITERS.AUTHORED_FACET_OPTIONS)

    if (!map[key]) {
      map[key] = [value]
    } else {
      map[key].push(value)
    }

    return map
  }, {})

  const fullMap = {
    ...authorSelectedFacetMap,
    ...userSelectedFacets,
  }

  return Object.keys(fullMap).map((key) => ({
    key,
    options: fullMap[key],
  }))
}

const getUnprefixedValue = (value: string) => {
  const [firstPart, ...restParts] = value.split(DELIMITERS.PREFIX)
  return restParts.length === 0 ? firstPart : restParts.join(DELIMITERS.PREFIX)
}

const useCategoryProductSearch = (config: CategoryProductSearch) => {
  const {
    authorSelectedFacets = [],
    authorCuratedFacets = [],
    defaultSort,
    defaultPerPage,
  } = config
  const {
    page: pageState,
    perPage: perPageState,
    searchTerm,
    searchedFor,
    sort,
    selectedFacets,
    setQueryParams,
  } = useCategoryProductSearchQueryParams(defaultSort, defaultPerPage)

  const { data, loading, error } = useCategoryProductSearchQuery({
    variables: {
      searchTerm,
      page: pageState,
      perPage: perPageState,
      sort: sort,
      selectedFacets: getAllSelectedFacets(
        authorSelectedFacets,
        selectedFacets
      ),
      facetSet: authorCuratedFacets,
    },
  })

  const nextSearchResults =
    data?.getProductSearchResults ?? DEFAULT_SEARCH_RESULTS
  const cachedSearchResults = useCacheCategoryProductSearch(
    nextSearchResults,
    loading
  )

  const term = searchTerm
  const searched = searchedFor
  const searchResults = loading ? cachedSearchResults : nextSearchResults
  const products = [...searchResults.items]
  const facets: SelectableFacet[] = searchResults.facets.map((facet) => {
    const selectedOptions = selectedFacets[facet.key] ?? []
    const formattedSelectedOptions = facet.prefix
      ? selectedOptions.map(getUnprefixedValue)
      : selectedOptions
    const options = facet.options.map((option) => ({
      ...option,
      selected: formattedSelectedOptions.includes(option.value),
    }))

    return {
      ...facet,
      options,
    }
  })

  const { numPages, itemCount, page, perPage } = searchResults.metadata
  const maxCountForPage = page * perPage
  const pageEnd =
    (itemCount || 0) < maxCountForPage ? itemCount : maxCountForPage
  const pageStart = maxCountForPage - perPage + 1

  const updateSearchTerm = (term?: string, searched?: string) =>
    setQueryParams({ term, page: 1, searched })
  const updatePage = (page?: number) => setQueryParams({ page })
  const updateResultsPerPage = (perPage?: number) =>
    setQueryParams({ perPage, page: 1 })
  const updateSort = (sort?: Sort) => setQueryParams({ sort, page: 1 })
  const updateFacets = (facet: FacetSelection, clearAll?: boolean) => {
    if (clearAll) {
      setQueryParams({ facets: {}, page: 1, term: '' })
      return
    }

    const { key, value, prefix } = facet
    const nextSelectedFacets = { ...selectedFacets }
    const currentValues = nextSelectedFacets[key]
    const storedValue = prefix ? `${prefix}${value}` : value
    const shouldRemove = currentValues && currentValues.includes(storedValue)

    let nextValues: string[] = []

    if (shouldRemove) {
      const removableItemIndex = currentValues.indexOf(value)
      nextValues = [...currentValues]
      nextValues.splice(removableItemIndex, 1)
    } else {
      nextValues = [value]

      if (currentValues) {
        nextValues = currentValues.concat(nextValues)
      }
    }

    nextSelectedFacets[key] = prefix
      ? nextValues.map((value) => `${prefix}${value}`)
      : nextValues

    if (nextValues.length === 0) {
      delete nextSelectedFacets[key]
    }

    setQueryParams({ facets: nextSelectedFacets, page: 1 })
  }

  // this will wipe out all other facets and apply this set only.
  const applyFacets = (facets: FacetSelection[]) => {
    const nextSelectedFacets = {}
    facets.forEach((f) => {
      const prefix = f.prefix || ''
      nextSelectedFacets[f.key] = [`${prefix}${f.value}`]
    })

    setQueryParams({ facets: nextSelectedFacets, page: 1, term: '' })
  }

  const selectedFacetsForView = Object.keys(selectedFacets).reduce(
    (nextSelectedFacets: FacetSelection[], key) => {
      const options = selectedFacets[key]

      options.forEach((value) => {
        nextSelectedFacets.push({
          key,
          value: getUnprefixedValue(value),
        })
      })

      return nextSelectedFacets
    },
    []
  )

  return {
    facets,
    products,
    error,
    loading,
    pageEnd,
    pageStart,
    numPages,
    page,
    sort,
    term,
    itemCount,
    searchTerm,
    searched,
    selectedFacets: selectedFacetsForView,
    updateSearchTerm,
    updatePage,
    updateResultsPerPage,
    updateSort,
    updateFacets,
    applyFacets,
  }
}

export default useCategoryProductSearch
