import React from 'react'
import { useIntl } from 'react-intl'
import CSRFacetGroup from './CSRFacetGroup'
import CSRFacet from './CSRFacet'
import facetMessages from '@utils/MessageSets/defineFacetMessages'
import { FacetSelection, SelectableFacet } from './useCategoryProductSearch'
import CSRSelectedFacets from './CSRSelectedFacets'

interface CSRFacetsProps {
  loading: boolean
  facets: SelectableFacet[]
  openFacets?: number
  term?: string
  handleUpdateFacets: (facet: FacetSelection | {}, clearAll?: boolean) => void
  handleUpdateSearchTerm?: (searchTerm?: string) => void
}
const CSRFacets: React.FC<CSRFacetsProps> = ({
  loading,
  facets,
  openFacets = 0,
  term,
  handleUpdateFacets,
  handleUpdateSearchTerm,
}) => {
  const intl = useIntl()
  return (
    <>
      <CSRSelectedFacets
        facets={facets}
        handleUpdateFacets={handleUpdateFacets}
        term={term}
        handleUpdateSearchTerm={handleUpdateSearchTerm}
      />
      {facets.map((facet, index) => {
        // making this key as constant 6 for max number of facet in a group (AEM3-164)
        // -------------------- //
        facet.numToDisplay = 6 //
        // -------------------- //q
        const isExpandedByDefault =
          index < openFacets || facet.options.some((facet) => facet.selected)
            ? (facet.isCollapsed = true)
            : (facet.isCollapsed = false)

        const title = facetMessages[facet.key.toUpperCase()]
          ? intl.formatMessage(facetMessages[facet.key.toUpperCase()])
          : facet.key

        const enTitleForAnalytics =
          facetMessages[facet.key.toUpperCase()].defaultMessage || facet.key

        return (
          <CSRFacetGroup
            key={facet.key}
            loading={loading}
            title={title}
            defaultExpanded={isExpandedByDefault}
            enTitleForAnalytics={enTitleForAnalytics}
          >
            <CSRFacet
              loading={loading}
              facet={facet}
              handleUpdateFacets={handleUpdateFacets}
              title={title}
            />
          </CSRFacetGroup>
        )
      })}
    </>
  )
}

export default CSRFacets
