import React from 'react'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { CategoryProductSearchFieldsFragment } from '@src/queries/CategoryProductSearchQuery.generated'
import { AdditionalColumn } from './CategorySearchResults'
import messages from '@src/utils/messages'
import ResponsiveCSRProduct from './ResponsiveCSRProduct'
import CompareDrawer from '@src/routes/SearchResults/CompareDrawer'

const useStyles = makeStyles((theme: Theme) => {
  return {
    tHeadRow: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightBold,
        '& > div': {
          padding: theme.spacing(0, 2),
          lineHeight: 1.3,
        },
      },
    },
    productCompare: {
      [theme.breakpoints.up('md')]: {
        width: '10%',
      },
    },
    productCompareWAddlColumns: {
      [theme.breakpoints.up('md')]: {
        width: '10%',
      },
    },
    productNumber: {
      [theme.breakpoints.up('md')]: {
        width: '12%',
      },
    },
    productNumberWAddlColumns: {
      [theme.breakpoints.up('md')]: {
        width: '12%',
      },
    },
    productName: {
      [theme.breakpoints.up('md')]: {
        width: '30%',
      },
    },
    description: {
      [theme.breakpoints.up('md')]: {
        width: '38%',
      },
    },
    desc1attr: {
      [theme.breakpoints.up('md')]: {
        width: '58%',
      },
    },
    desc2attr: {
      [theme.breakpoints.up('md')]: {
        width: '42%',
      },
    },
    desc3attr: {
      [theme.breakpoints.up('md')]: {
        width: '42%',
      },
    },
    attr1: {
      width: '18%',
    },
    attr2: {
      width: '18%',
    },
    attr3: {
      width: '12%',
    },
    pricing: {
      width: '17%',
      textAlign: 'right',
    },
    mobileFilterSortContainer: {
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }
})

interface ResponsiveCSRProductTableProps {
  products: CategoryProductSearchFieldsFragment['items']
  additionalColumns: AdditionalColumn[]
}
const ResponsiveCSRProductTable: React.FC<ResponsiveCSRProductTableProps> = ({
  products,
  additionalColumns,
}) => {
  const classes = useStyles()
  const totalAdditionalColumns = additionalColumns.length
  const isCompositeDimensionColumn = additionalColumns.find(
    (item) => item.value === 'composite dimension.default'
  )

  return (
    <div>
      <div className={classes.tHeadRow}>
        <div
          className={clsx(classes.productCompare, {
            [classes.productCompareWAddlColumns]: totalAdditionalColumns,
          })}
        >
          <FormattedMessage {...messages.COMPARE} />
        </div>
        <div
          className={clsx(classes.productNumber, {
            [classes.productNumberWAddlColumns]: totalAdditionalColumns,
          })}
        >
          <FormattedMessage {...messages.PRODUCT_NUMBER} />
        </div>
        <div className={clsx(classes.productName)}>
          <FormattedMessage {...messages.PRODUCT_NAME} />
        </div>
        <div
          className={clsx(classes.description, {
            [classes.desc1attr]: totalAdditionalColumns === 1,
            [classes.desc2attr]: totalAdditionalColumns === 2,
            [classes.desc3attr]: totalAdditionalColumns === 3,
          })}
        >
          <FormattedMessage {...messages.PRODUCT_DESCRIPTION} />
        </div>
        {additionalColumns.map((col) => (
          <div
            key={col.value}
            className={clsx({
              [classes.attr1]: totalAdditionalColumns === 1,
              [classes.attr2]: totalAdditionalColumns === 2,
              [classes.attr3]: totalAdditionalColumns === 3,
            })}
          >
            {isCompositeDimensionColumn ? (
              <FormattedMessage
                id="COMPOSITE_DIMENSION"
                defaultMessage="Composite Dimension"
              />
            ) : (
              col.text
            )}
          </div>
        ))}
        <div className={classes.pricing}>
          <FormattedMessage {...messages.PRICING} />
        </div>
      </div>
      <div>
        {products.map((product) => {
          if (product.__typename !== 'Product') return null
          return (
            <ResponsiveCSRProduct
              key={product.productNumber}
              product={product}
              additionalColumns={additionalColumns}
            />
          )
        })}
      </div>
      <CompareDrawer isAemDrawer />
    </div>
  )
}

export default ResponsiveCSRProductTable
