// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import { DynamicProductFragmentDoc } from '../fragments/DynamicProduct.generated';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type BestSellerProductsQueryVariables = Types.Exact<{
  input: Types.GetBestSellerProductsInput;
}>;


export type BestSellerProductsQuery = { __typename?: 'Query', getBestSellerProducts?: { __typename?: 'AssociatedProducts', products: Array<{ __typename?: 'Product', id?: string | null, productKey: string, productNumber: string, productSku?: string | null, lastBoughtUnix?: string | null, name: string, description?: string | null, gaProductCode?: string | null, isMarketplace: boolean, erp_type?: Array<string> | null, tags?: string | null, discount?: string | null, brand: { __typename?: 'Brand', key: string, erpKey: string, name?: string | null, color?: string | null }, images: Array<{ __typename?: 'CatalogImage', altText?: string | null, mediumUrl: string }> }> } | null };


export const BestSellerProductsDocument = gql`
    query BestSellerProducts($input: GetBestSellerProductsInput!) {
  getBestSellerProducts(input: $input) {
    products {
      ...DynamicProduct
    }
  }
}
    ${DynamicProductFragmentDoc}`;
export type BestSellerProductsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BestSellerProductsQuery, BestSellerProductsQueryVariables>, 'query'> & ({ variables: BestSellerProductsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BestSellerProductsComponent = (props: BestSellerProductsComponentProps) => (
      <ApolloReactComponents.Query<BestSellerProductsQuery, BestSellerProductsQueryVariables> query={BestSellerProductsDocument} {...props} />
    );
    

/**
 * __useBestSellerProductsQuery__
 *
 * To run a query within a React component, call `useBestSellerProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBestSellerProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBestSellerProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBestSellerProductsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<BestSellerProductsQuery, BestSellerProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BestSellerProductsQuery, BestSellerProductsQueryVariables>(BestSellerProductsDocument, options);
      }
export function useBestSellerProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BestSellerProductsQuery, BestSellerProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BestSellerProductsQuery, BestSellerProductsQueryVariables>(BestSellerProductsDocument, options);
        }
export type BestSellerProductsQueryHookResult = ReturnType<typeof useBestSellerProductsQuery>;
export type BestSellerProductsLazyQueryHookResult = ReturnType<typeof useBestSellerProductsLazyQuery>;
export type BestSellerProductsQueryResult = ApolloReactCommon.QueryResult<BestSellerProductsQuery, BestSellerProductsQueryVariables>;