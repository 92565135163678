// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import { DynamicProductFragmentDoc } from '../fragments/DynamicProduct.generated';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type BestSellerProductsByRegionQueryVariables = Types.Exact<{
  input: Types.GetBestSellerProductsByRegionInput;
}>;


export type BestSellerProductsByRegionQuery = { __typename?: 'Query', getBestSellerProductsByRegion?: { __typename?: 'AssociatedProducts', products: Array<{ __typename?: 'Product', id?: string | null, productKey: string, productNumber: string, productSku?: string | null, lastBoughtUnix?: string | null, name: string, description?: string | null, gaProductCode?: string | null, isMarketplace: boolean, erp_type?: Array<string> | null, tags?: string | null, discount?: string | null, brand: { __typename?: 'Brand', key: string, erpKey: string, name?: string | null, color?: string | null }, images: Array<{ __typename?: 'CatalogImage', altText?: string | null, mediumUrl: string }> }> } | null };


export const BestSellerProductsByRegionDocument = gql`
    query BestSellerProductsByRegion($input: GetBestSellerProductsByRegionInput!) {
  getBestSellerProductsByRegion(input: $input) {
    products {
      ...DynamicProduct
    }
  }
}
    ${DynamicProductFragmentDoc}`;
export type BestSellerProductsByRegionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BestSellerProductsByRegionQuery, BestSellerProductsByRegionQueryVariables>, 'query'> & ({ variables: BestSellerProductsByRegionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BestSellerProductsByRegionComponent = (props: BestSellerProductsByRegionComponentProps) => (
      <ApolloReactComponents.Query<BestSellerProductsByRegionQuery, BestSellerProductsByRegionQueryVariables> query={BestSellerProductsByRegionDocument} {...props} />
    );
    

/**
 * __useBestSellerProductsByRegionQuery__
 *
 * To run a query within a React component, call `useBestSellerProductsByRegionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBestSellerProductsByRegionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBestSellerProductsByRegionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBestSellerProductsByRegionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<BestSellerProductsByRegionQuery, BestSellerProductsByRegionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BestSellerProductsByRegionQuery, BestSellerProductsByRegionQueryVariables>(BestSellerProductsByRegionDocument, options);
      }
export function useBestSellerProductsByRegionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BestSellerProductsByRegionQuery, BestSellerProductsByRegionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BestSellerProductsByRegionQuery, BestSellerProductsByRegionQueryVariables>(BestSellerProductsByRegionDocument, options);
        }
export type BestSellerProductsByRegionQueryHookResult = ReturnType<typeof useBestSellerProductsByRegionQuery>;
export type BestSellerProductsByRegionLazyQueryHookResult = ReturnType<typeof useBestSellerProductsByRegionLazyQuery>;
export type BestSellerProductsByRegionQueryResult = ApolloReactCommon.QueryResult<BestSellerProductsByRegionQuery, BestSellerProductsByRegionQueryVariables>;