// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import { DynamicProductFragmentDoc } from '../fragments/DynamicProduct.generated';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AemDiscountedProductsQueryVariables = Types.Exact<{
  input: Types.GetAemDiscountedProductsInput;
}>;


export type AemDiscountedProductsQuery = { __typename?: 'Query', getAEMDiscountedProducts?: { __typename?: 'AssociatedProducts', products: Array<{ __typename?: 'Product', id?: string | null, productKey: string, productNumber: string, productSku?: string | null, lastBoughtUnix?: string | null, name: string, description?: string | null, gaProductCode?: string | null, isMarketplace: boolean, erp_type?: Array<string> | null, tags?: string | null, discount?: string | null, brand: { __typename?: 'Brand', key: string, erpKey: string, name?: string | null, color?: string | null }, images: Array<{ __typename?: 'CatalogImage', altText?: string | null, mediumUrl: string }> }> } | null };


export const AemDiscountedProductsDocument = gql`
    query AEMDiscountedProducts($input: GetAEMDiscountedProductsInput!) {
  getAEMDiscountedProducts(input: $input) {
    products {
      ...DynamicProduct
    }
  }
}
    ${DynamicProductFragmentDoc}`;
export type AemDiscountedProductsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AemDiscountedProductsQuery, AemDiscountedProductsQueryVariables>, 'query'> & ({ variables: AemDiscountedProductsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AemDiscountedProductsComponent = (props: AemDiscountedProductsComponentProps) => (
      <ApolloReactComponents.Query<AemDiscountedProductsQuery, AemDiscountedProductsQueryVariables> query={AemDiscountedProductsDocument} {...props} />
    );
    

/**
 * __useAemDiscountedProductsQuery__
 *
 * To run a query within a React component, call `useAemDiscountedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAemDiscountedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAemDiscountedProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAemDiscountedProductsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<AemDiscountedProductsQuery, AemDiscountedProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AemDiscountedProductsQuery, AemDiscountedProductsQueryVariables>(AemDiscountedProductsDocument, options);
      }
export function useAemDiscountedProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AemDiscountedProductsQuery, AemDiscountedProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AemDiscountedProductsQuery, AemDiscountedProductsQueryVariables>(AemDiscountedProductsDocument, options);
        }
export type AemDiscountedProductsQueryHookResult = ReturnType<typeof useAemDiscountedProductsQuery>;
export type AemDiscountedProductsLazyQueryHookResult = ReturnType<typeof useAemDiscountedProductsLazyQuery>;
export type AemDiscountedProductsQueryResult = ApolloReactCommon.QueryResult<AemDiscountedProductsQuery, AemDiscountedProductsQueryVariables>;