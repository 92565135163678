const FormAssemblyHeader = () => {
  return (
    <>
      <script
        type="text/javascript"
        src="https://sial.tfaforms.net/wForms/3.11/js/localization-en_US.js?v=813cb4fab17d46c586bbe42b1bcc8e38ca00cf31"
      ></script>
      <script
        type="text/javascript"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
          if (wFORMS && wFORMS.behaviors && wFORMS.behaviors.prefill) {
            wFORMS.behaviors.prefill.skip = false;
          }
          document.addEventListener("DOMContentLoaded", function () {
            var FORM_TIME_START = Math.floor((new Date).getTime() / 1000);
            var formElement = document.getElementById("tfa_0");
            if (null === formElement) {
              formElement = document.getElementById("0");
            }
            var appendJsTimerElement = function () {
              var formTimeDiff = Math.floor((new Date).getTime() / 1000) - FORM_TIME_START;
              var cumulatedTimeElement = document.getElementById("tfa_dbCumulatedTime");
              if (null !== cumulatedTimeElement) {
                var cumulatedTime = parseInt(cumulatedTimeElement.value);
                if (null !== cumulatedTime && cumulatedTime > 0) {
                  formTimeDiff += cumulatedTime;
                }
              }
              var jsTimeInput = document.createElement("input");
              jsTimeInput.setAttribute("type", "hidden");
              jsTimeInput.setAttribute("value", formTimeDiff.toString());
              jsTimeInput.setAttribute("name", "tfa_dbElapsedJsTime");
              jsTimeInput.setAttribute("id", "tfa_dbElapsedJsTime");
              jsTimeInput.setAttribute("autocomplete", "off");
              if (null !== formElement) {
                formElement.appendChild(jsTimeInput);
              }
            };
            if (null !== formElement) {
              if (formElement.addEventListener) {
                formElement.addEventListener('submit', appendJsTimerElement, false);
              } else if (formElement.attachEvent) {
                formElement.attachEvent('onsubmit', appendJsTimerElement);
              }
            }
          });`,
        }}
      />

      <link
        rel="stylesheet"
        type="text/css"
        href="https://sial.tfaforms.net/css/kalendae.css"
      />
      <script
        type="text/javascript"
        src="https://sial.tfaforms.net/js/kalendae/kalendae.standalone.min.js"
      ></script>
      <script
        type="text/javascript"
        src="https://sial.tfaforms.net/wForms/3.11/js/wforms_calendar.js"
      ></script>
    </>
  )
}

export default FormAssemblyHeader
