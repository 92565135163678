import React from 'react'
import { useIntl } from 'react-intl'
import { MapTo } from '@adobe/aem-react-editable-components'
import aemMessages from '@src/aem-content/messages'
import LocationMap from '@sial/aem-cms-components/dist/Map/LocationMap'

const LocationMapAdapter: React.FC<any> = (props) => {
  const intl = useIntl()
  const venue = intl.formatMessage(aemMessages['EVENT_VENUE_LABEL'])

  const translations = {
    venue,
  }

  return <LocationMap {...props} translations={translations} />
}

export default MapTo('cms-commons/components/content/locationmap')(
  LocationMapAdapter
)
