import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { AemDynamicProductTableProps } from './index'
import { useMultipleProductDetailSubstanceProductQuery } from '@src/queries/MultipleProductDetailsQuerySubstanceProduct.generated'
import DOMPurify from 'isomorphic-dompurify'
import ProductTable from './ProductTable'
import SearchResultsError from '@src/components/SearchResultsError'

const useStyles = makeStyles((theme: Theme) => ({
  tableTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(20),
    margin: theme.spacing(4, 0),
  },
}))

const DynamicProductTableWrapper: React.FC<AemDynamicProductTableProps> = ({
  additionalColumns,
  anchorId,
  isShowImage,
  isShowBrand,
  isStaticContent,
  maxRows,
  products,
  tableTitle,
}) => {
  const { loading, error, data } =
    useMultipleProductDetailSubstanceProductQuery({
      variables: {
        productInputs: products,
      },
      errorPolicy: 'all',
      ssr: false,
    })
  const classes = useStyles()
  return products.length ? (
    <div id={anchorId}>
      {tableTitle && (
        <div
          className={classes.tableTitle}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tableTitle) }}
        />
      )}
      {(function renderInternal() {
        if (loading) return <div>Loading</div>
        if (error && !data?.getMultipleProductDetails)
          return <SearchResultsError error={error} />
        return (
          <div className={isStaticContent ? 'aem-ms-cmp' : ''}>
            <ProductTable
              // always want to display badges product level since there is no substance in this case
              additionalColumns={additionalColumns}
              displayBadgesOnProduct
              error={error}
              focus={'sial'}
              isShowBrand={isShowBrand}
              isShowImage={isShowImage}
              maxRows={maxRows}
              // @ts-ignore
              products={data.getMultipleProductDetails}
            />
          </div>
        )
      })()}
    </div>
  ) : null
}

export default DynamicProductTableWrapper
