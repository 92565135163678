/**
 * Using a dedicated `types` file due to types being shared in both
 * dependent and implementing files, which may cause a cyclical reference.
 */
export type SelectedFacetState = Record<string, string[]>
export enum QueryParamKeys {
  SearchTerm = 'query',
  Page = 'page',
  PerPage = 'offset',
  Sort = 'sort',
  SelectedFacets = 'facets',
}
