import React, { FC, useCallback, useState } from 'react'
import {
  MappedComponentProperties,
  MapTo,
} from '@adobe/aem-react-editable-components'
import { ProductSelector } from '@src/components/ProductSelector/ProductSelector'
import { AntibodySelectorTool } from './AntibodySelectorTool'
import {
  productSelectorAnalyticsConfig,
  SelectorToolType,
} from '@src/components/ProductSelector/productSelectorAnalyticsConfig'
import { sendSelectorToolEvent } from '@src/utils/analytics'
import getConfig from 'next/config'
import { MillexFilterSelectorTool } from '@src/components/SelectorTool/MillexFilterSelectorTool'

interface ProductSelectorProps extends MappedComponentProperties {
  selectorType?: 'antibody' | 'filtration'
}

const {
  publicRuntimeConfig: { featureFlags },
} = getConfig()

const ProductSelectorAdapter: FC<ProductSelectorProps> = ({ selectorType }) => {
  const [showSelectorTool, setShowSelectorTool] = useState(false)

  const showAntibodySelectorTool =
    selectorType === 'antibody' && showSelectorTool

  const showMillexSelectorTool =
    selectorType === 'filtration' &&
    featureFlags.millexFilterSelector &&
    showSelectorTool

  const showProductSelector =
    selectorType === 'antibody' ||
    (selectorType === 'filtration' && featureFlags.millexFilterSelector)

  const toolSelectorType: SelectorToolType =
    selectorType === 'filtration'
      ? SelectorToolType.millex
      : SelectorToolType.aemPageAntibody

  const selectorAnalyticsConfig = productSelectorAnalyticsConfig(
    toolSelectorType as SelectorToolType,
    {
      productId: '',
      productKey: '',
      productBrandKey: '',
      productName: '',
    }
  )

  const handleShowSelectorTool = useCallback(
    (openType: 'open button' | 'open link') => {
      setShowSelectorTool(true)
      sendSelectorToolEvent(selectorAnalyticsConfig[openType])
    },
    [showSelectorTool, selectorAnalyticsConfig]
  )
  const handleCloseSelectorTool = useCallback(
    (closeType: 'close' | 'ignore') => {
      setShowSelectorTool(false)
      sendSelectorToolEvent(selectorAnalyticsConfig[closeType])
    },
    [showSelectorTool, selectorAnalyticsConfig]
  )

  return (
    <>
      {showProductSelector && (
        <ProductSelector
          onButtonClick={() => handleShowSelectorTool('open button')}
          onLinkClick={() => handleShowSelectorTool('open link')}
          isAemPageProductSelector
        />
      )}
      {showAntibodySelectorTool && (
        <AntibodySelectorTool
          onClose={() => handleCloseSelectorTool('close')}
          onDismiss={() => handleCloseSelectorTool('ignore')}
          open
          toolType="antibody"
        />
      )}
      {showMillexSelectorTool && (
        <MillexFilterSelectorTool
          onClose={() => handleCloseSelectorTool('close')}
          onDismiss={() => handleCloseSelectorTool('ignore')}
          open
          toolType="millex"
        />
      )}
    </>
  )
}
export default MapTo('cms-commons/components/content/productselector')(
  ProductSelectorAdapter
)
