import React from 'react'
import { MapTo } from '@adobe/aem-react-editable-components'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useAemHeaderFooterQuery } from '@src/queries/AemQuery.generated'
import clsx from 'clsx'
import { Skeleton } from '@material-ui/lab'
import DOMPurify from 'isomorphic-dompurify'

interface Item {
  title: string
  url: string
  openNewTab: boolean
  childrens?: Item[]
}

interface ResultItem {
  mainTitle: string
  title: string
  url: string
  openNewTab: boolean
}

interface ExtractedTitles {
  r1: ResultItem[]
  r2: ResultItem[]
  r3: ResultItem[]
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapSitemap: {
    '& h2': {
      margin: theme.spacing(8, 0, 6),
      fontSize: theme.typography.pxToRem(20),
    },
    '& h5': {
      margin: 0,
      fontSize: theme.typography.pxToRem(14),
    },
    '& ul': {
      padding: 0,
      margin: theme.spacing(0, 0, 4),
      '& > li': {
        listStyle: 'outside none none',

        '& > a': {
          fontSize: theme.typography.pxToRem(12),
          display: 'block',
          marginTop: theme.spacing(2),
          lineHeight: 'normal',
        },
      },
    },
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  colInit: {
    flex: '0 0 100%',
    columnCount: 1,
    marginBottom: theme.spacing(2),
    '& > div': {
      display: 'grid',
      gridTemplateRows: '1fr auto',
      breakInside: 'avoid',
    },
    [theme.breakpoints.up('md')]: {
      columnCount: 3,
      flex: '0 0 75%',
      marginBottom: theme.spacing(0),
      gap: theme.spacing(0, 2),
    },
  },
  lastCol: {
    flex: '0 0 100%',
    [theme.breakpoints.up('md')]: {
      flex: '0 0 25%',
      marginLeft: `-${theme.spacing(2)}px`,
    },
  },
  stackedRow: {
    [theme.breakpoints.up('md')]: {
      flex: '0 0 75%',
      margin: theme.spacing(4, 0, 0),
      columnCount: 3,
      gap: theme.spacing(0, 2),
      '& > div': {
        paddingLeft: 0,
      },
    },
  },
  colNoChild: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
    },
    '& ul': {
      marginBottom: 0,
      '& li': {
        margin: 0,
        '& > a': {
          fontSize: theme.typography.pxToRem(14),
        },
      },
    },
    '&:first-child': {
      '& ul': {
        marginBottom: 0,
        '& li': {
          margin: 0,
          '& > a': {
            margin: 0,
          },
        },
      },
    },
  },
  lastColFirstLevel: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8),
    },
  },
}))

const SiteMap: React.FC<any> = ({ headline, anchorId }) => {
  const classes = useStyles()
  const { loading, data } = useAemHeaderFooterQuery()
  const totalItems = data?.aemHeaderFooter?.header?.topnav?.slice(0, 3) || []

  function extractTitles(items?: Item[] | null): ExtractedTitles {
    const result1: ResultItem[] = []
    const result2: ResultItem[] = []
    const result3: ResultItem[] = []

    items?.forEach((itemParent) => {
      if (itemParent.childrens && itemParent.childrens.length > 0) {
        itemParent.childrens.forEach((item2) => {
          if (item2.childrens && item2.childrens.length > 0) {
            item2.childrens.forEach((item) => {
              result3.push({
                title: item.title.trim(),
                url: item.url,
                openNewTab: item.openNewTab,
                mainTitle: item2.title,
              })
            })
          } else {
            result2.push({
              title: item2.title,
              url: item2.url,
              openNewTab: item2.openNewTab,
              mainTitle: item2.title,
            })
          }
        })
      } else {
        result1.push({
          title: itemParent.title,
          url: itemParent.url,
          openNewTab: itemParent.openNewTab,
          mainTitle: itemParent.title,
        })
      }
    })

    return {
      r1: result1,
      r2: result2,
      r3: result3,
    }
  }
  const removeDuplicates = (items: ResultItem[]): ResultItem[] => {
    const seen = new Set<string>()
    return items.filter((item) => {
      const duplicate = seen.has(item.title)
      seen.add(item.title)
      return !duplicate
    })
  }
  const reduceData = (items: ResultItem[]): Record<string, ResultItem[]> => {
    return items.reduce(
      (acc, item) => {
        if (!acc[item.mainTitle]) {
          acc[item.mainTitle] = []
        }
        acc[item.mainTitle].push(item) // Push the entire item
        return acc
      },
      {} as Record<string, ResultItem[]>
    )
  }

  const resultReduceData: {
    L1: Record<string, ResultItem[]>
    L2: Record<string, ResultItem[]>
    L3: Record<string, ResultItem[]>
  }[] =
    totalItems
      ?.map((item) => {
        // @ts-ignore
        const resultData = extractTitles(item?.items)
        return {
          L1: reduceData(removeDuplicates(resultData.r1)),
          L2: reduceData(removeDuplicates(resultData.r2)),
          L3: reduceData(removeDuplicates(resultData.r3)),
        }
      })
      .filter(Boolean) ?? []
  if (loading) {
    return (
      <div id={anchorId}>
        <Skeleton width="100%" variant="rect" height={150} animation="wave" />
        <Skeleton width="50%" />
        <Skeleton width="50%" />
      </div>
    )
  } else {
    return (
      <div id={anchorId}>
        {headline && <h1>{headline}</h1>}

        {resultReduceData &&
          resultReduceData.map((item, idx) => (
            <div className={classes.wrapSitemap} key={`wrap-${idx}`}>
              {totalItems && (
                <h2
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(totalItems[idx]?.title ?? ''),
                  }}
                />
              )}
              <div className={classes.row}>
                <div className={classes.colInit}>
                  {Object.entries(item.L3)
                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                    .map(([mainTitle, subChilds]) => (
                      <div className="col" key={`colSitemap-L3-${mainTitle}`}>
                        <h5
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(mainTitle),
                          }}
                        />
                        <ul>
                          {subChilds.map((childrenpage, j) => (
                            <li key={`link-L3-${j}`}>
                              <a
                                href={childrenpage.url}
                                target={childrenpage.openNewTab ? '_blank' : ''}
                                rel="noreferrer"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    childrenpage.title
                                  ),
                                }}
                              />
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                </div>
                <div
                  className={clsx(
                    classes.lastCol,
                    idx !== 0 ? classes.stackedRow : ''
                  )}
                >
                  {Object.entries(item.L2)
                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                    .map(([mainTitle, subChilds]) => (
                      <div
                        className={classes.colNoChild}
                        key={`colSitemap-L2-${mainTitle}`}
                      >
                        <ul>
                          {subChilds.map((childrenpage, j) => (
                            <li key={`link-L2-${j}`}>
                              <a
                                href={childrenpage.url}
                                target={childrenpage.openNewTab ? '_blank' : ''}
                                rel="noreferrer"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    childrenpage.title
                                  ),
                                }}
                              />
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  {Object.entries(item.L1)
                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                    .map(([mainTitle, subChilds]) => (
                      <div
                        className={clsx(
                          classes.colNoChild,
                          classes.lastColFirstLevel
                        )}
                        key={`colSitemap-L1-${mainTitle}`}
                      >
                        <ul>
                          {subChilds.map((childrenpage, j) => (
                            <li key={`link-L1-${j}`}>
                              <a
                                href={childrenpage.url}
                                target={childrenpage.openNewTab ? '_blank' : ''}
                                rel="noreferrer"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    childrenpage.title
                                  ),
                                }}
                              />
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}
      </div>
    )
  }
}

export default MapTo('cms-commons/components/content/sitemap')(SiteMap)
