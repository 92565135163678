import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Theme, Button } from '@material-ui/core'
import { productDetailRoute } from '@src/routes'
import { Link } from '@src/components/Link'
import ProductPriceAvailability from '@src/components/ProductPriceAvailability'
import MapAttributeValues from '@src/components/MapAttributeValues'
import HandleMarkup from '@src/components/HandleMarkup'
import { AddToCartPagesEnum } from '@utils/analytics/enums'
import { EventValues } from '@sial/common-utils'
import messages from '@utils/messages'
import { CategorySubstanceProductFieldsFragment } from '@src/queries/CategoryProductSearchQuery.generated'
import { FormattedMessage, useIntl } from 'react-intl'
import ResponsiveProductPAModal from '@src/components/ResponsiveProductPAModal'
import CaretDownIcon from '@icons/CaretDownIcon'
import { AdditionalColumn } from './CategorySearchResults'
import ProductPaMessage from '@src/components/ProductPriceAvailability/ProductPaMessage'
import LiquidCheckbox from '@src/components/LiquidCheckbox'
import { MAX_COMPARE_SIZE, useCompareProducts } from '@utils/useCompareProducts'
import { sendPricingAndAvailabilityEvent } from '@src/utils/analytics/pricingAndAvailability'

const useStyles = makeStyles((theme: Theme) => {
  return {
    tBodyRow: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(4, 5),
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      '&:first-of-type': {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
      },
      '&:nth-child(2n)': {
        backgroundColor: theme.palette.background.grey,
      },
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing(0),
        '&:nth-child(2n)': {
          backgroundColor: 'white',
        },
      },
      '& > div': {
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(2),
          lineHeight: 1.3,
        },
      },
      '& a:hover': {
        textDecoration: 'none',
      },
    },
    pricingExpanded: {
      backgroundColor: theme.palette.background.grey,
      '&:nth-child(2n)': {
        backgroundColor: theme.palette.background.grey,
      },
    },
    productName: {
      cursor: 'pointer',
      fontSize: theme.typography.pxToRem(16),
      wordBreak: 'break-all',
      marginTop: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(14),
        width: '30%',
        marginTop: 2,
      },
      '& a *': {
        wordBreak: 'break-word',
      },
    },
    description: {
      marginTop: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        width: '38%',
        marginTop: 2,
      },
      '& sup, sub': {
        verticalAlign: 'baseline',
        position: 'relative',
        top: '-0.4em',
      },
      '& sub': {
        top: '0.1em',
      },
      '& a': {
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.common.black,
      },
    },
    desc1attr: {
      [theme.breakpoints.up('md')]: {
        width: '58%',
      },
    },
    desc2attr: {
      [theme.breakpoints.up('md')]: {
        width: '42%',
      },
    },
    desc3attr: {
      [theme.breakpoints.up('md')]: {
        width: '42%',
      },
    },
    attribute: {
      marginTop: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        marginTop: 2,
      },
    },
    attr1: {
      [theme.breakpoints.up('md')]: {
        width: '18%',
      },
    },
    attr2: {
      [theme.breakpoints.up('md')]: {
        width: '18%',
      },
    },
    attr3: {
      [theme.breakpoints.up('md')]: {
        width: '12%',
      },
    },
    desktopPricing: {
      display: 'none',
      backgroundColor: theme.palette.background.grey,
      marginTop: -13,
      padding: theme.spacing(0, 2, 1),
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
      '& .MuiButton-containedPrimary': {
        color: 'white',
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
    attributeKey: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    emptyResult: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    pricingColumn: {
      [theme.breakpoints.up('md')]: {
        width: '17%',
        textAlign: 'right',
      },
    },
    productCheckbox: {
      fontSize: theme.typography.pxToRem(16),
      wordBreak: 'break-all',
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
        fontSize: theme.typography.pxToRem(14),
        width: '10%',
        marginTop: 2,
      },
    },
    productCheckboxWAddlColumns: {
      [theme.breakpoints.up('md')]: {
        width: '10%',
      },
    },
    productNumber: {
      fontSize: theme.typography.pxToRem(16),
      wordBreak: 'break-all',
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(14),
        width: '12%',
        marginTop: 2,
      },
      '& > a': {
        color: 'inherit',
        fontWeight: 'normal',
      },
    },
    productNumberWAddlColumns: {
      [theme.breakpoints.up('md')]: {
        width: '12%',
      },
    },
    mobileViewPricing: {
      marginTop: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    mobileViewPricingButton: {
      minWidth: 0,
      padding: 0,
    },
    desktopViewPricing: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    expandPricingButton: {
      minWidth: 0,
      padding: 0,
      color: theme.palette.primary.main,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.5,
      fontWeight: 900,
    },
    expandPricingIcon: {
      fontSize: theme.typography.pxToRem(10),
      marginLeft: theme.spacing(1),
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
      marginTop: theme.spacing(1 / 2),
    },
    iconExpanded: {
      transform: 'rotate(180deg)',
    },
  }
})

interface ResponsiveProductProps {
  product: CategorySubstanceProductFieldsFragment
  additionalColumns: AdditionalColumn[]
}

const ResponsiveProduct: React.FC<ResponsiveProductProps> = ({
  product,
  additionalColumns,
}) => {
  const { formatMessage } = useIntl()
  const [pricingExpanded, setPricingExpanded] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const totalAdditionalColumns = additionalColumns.length
  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => setIsMounted(true), [])
  const { compareProducts, addCompareProduct, removeCompareProduct } =
    useCompareProducts()
  const isCompared = compareProducts.some(
    ({ productKey, brandKey }) =>
      productKey === product.productKey && brandKey === product.brand.key
  )
  const compareDisabled = !isMounted
    ? false
    : !isCompared && compareProducts.length >= MAX_COMPARE_SIZE
  const productCompareValues = {
    productKey: product.productKey,
    productNumber: product.productNumber,
    brandKey: product.brand.key,
    images: product.images,
    productName: product.name,
    brandName: product.brand.name,
  }
  const handlePricingClick = () => {
    setPricingExpanded((curr) => !curr)
    sendPricingAndAvailabilityEvent(
      pricingExpanded ? EventValues.ClosePandA : EventValues.ExpandPandA,
      product.productNumber,
      {
        clickType: 'list',
        clickAction: 'View P&A',
        productId: product.productNumber,
        productName: product.name,
        productBrand: product.brand.key,
        component: 'list',
        elementType: 'accordion',
        linkText: pricingExpanded ? 'hide' : 'expand',
      }
    )
  }
  const closePricing = () => {
    setModalOpen(false)
    sendPricingAndAvailabilityEvent(
      EventValues.ClosePandA,
      product.productNumber
    )
  }
  const classes = useStyles()

  return (
    <>
      <div
        className={clsx(classes.tBodyRow, {
          [classes.pricingExpanded]: pricingExpanded,
        })}
      >
        <div
          className={clsx(classes.productCheckbox, {
            [classes.productCheckboxWAddlColumns]: totalAdditionalColumns,
          })}
        >
          <LiquidCheckbox
            name={`${formatMessage(messages.COMPARE)}-${product.productNumber}`}
            checked={isMounted && isCompared}
            onChange={(e) => {
              e.target.checked
                ? addCompareProduct(productCompareValues)
                : removeCompareProduct(productCompareValues)
            }}
            aria-label={`${formatMessage(messages.COMPARE)} ${
              product.productNumber
            }`}
            disabled={compareDisabled}
          />
        </div>
        <div
          className={clsx(classes.productNumber, {
            [classes.productNumberWAddlColumns]: totalAdditionalColumns,
          })}
        >
          {product.brand.key && product.productNumber ? (
            <Link
              {...productDetailRoute.index(
                product.brand.key,
                product.productKey
              )}
            >
              <a>
                {product.productNumber.startsWith('CB_')
                  ? formatMessage(messages.DETAILS)
                  : product.productNumber}
              </a>
            </Link>
          ) : (
            <>
              {product.productNumber.startsWith('CB_')
                ? formatMessage(messages.DETAILS)
                : product.productNumber}
            </>
          )}
        </div>
        <div className={classes.productName}>
          <Link
            {...productDetailRoute.index(product.brand.key, product.productKey)}
          >
            <a>
              {product.legalName || product.name ? (
                <b>
                  <HandleMarkup value={product.legalName || product.name} />
                </b>
              ) : null}
            </a>
          </Link>
        </div>
        <div
          className={clsx(classes.description, {
            [classes.desc1attr]: totalAdditionalColumns === 1,
            [classes.desc2attr]: totalAdditionalColumns === 2,
            [classes.desc3attr]: totalAdditionalColumns === 3,
          })}
        >
          <Link
            {...productDetailRoute.index(product.brand.key, product.productKey)}
          >
            <a>
              {product.legalNameSuffix || product.description ? (
                <HandleMarkup
                  value={product.legalNameSuffix || product.description}
                />
              ) : null}
            </a>
          </Link>
        </div>
        {additionalColumns.map((col: AdditionalColumn) => {
          const attribute = product.attributes.find(
            (att) => att.key === col.value
          )
          return (
            <div
              key={col.value}
              className={clsx(classes.attribute, {
                [classes.attr1]: totalAdditionalColumns === 1,
                [classes.attr2]: totalAdditionalColumns === 2,
                [classes.attr3]: totalAdditionalColumns === 3,
              })}
            >
              <div className={classes.attributeKey}>{col.text}</div>
              {attribute?.values && (
                <MapAttributeValues values={attribute.values} />
              )}
            </div>
          )
        })}
        <div className={classes.pricingColumn}>
          <div className={classes.desktopViewPricing}>
            <Button
              className={classes.expandPricingButton}
              onClick={handlePricingClick}
              data-testid="toggle-show-pricing"
            >
              {pricingExpanded ? (
                <FormattedMessage {...messages.HIDE} />
              ) : (
                <FormattedMessage
                  id="SUBSTANCE_VIEW_PRICING"
                  defaultMessage="View Pricing"
                />
              )}
              <CaretDownIcon
                className={clsx(classes.expandPricingIcon, {
                  [classes.iconExpanded]: pricingExpanded,
                })}
              />
            </Button>
          </div>
          <div className={classes.mobileViewPricing}>
            <Button
              className={classes.mobileViewPricingButton}
              onClick={() => setModalOpen(true)}
            >
              <FormattedMessage
                id="SUBSTANCE_VIEW_PRICING"
                defaultMessage="View Pricing"
              />
            </Button>
          </div>
        </div>
      </div>
      {pricingExpanded && (
        <div className={clsx(classes.desktopPricing)}>
          {!product.paMessage ? (
            <ProductPriceAvailability
              productName={product.name}
              productNumber={product.productNumber}
              productDescription={product.description ?? ''}
              brandKey={product.brand.key}
              gaType={AddToCartPagesEnum.Substance}
              isMarketplace={product.isMarketplace}
              marketplaceOfferId={product.marketplaceOfferId || ''}
              marketplaceSellerId={product.marketplaceSellerId || ''}
              productAttributes={product.attributes}
              displaySellerName={product.displaySellerName || ''}
            />
          ) : (
            <ProductPaMessage
              productNumber={product.productNumber}
              brandKey={product.brand.key}
              paMessage={product.paMessage}
            />
          )}
        </div>
      )}
      {modalOpen && (
        <ResponsiveProductPAModal
          onClose={closePricing}
          substanceName={product.name}
          productNumber={product.productNumber}
          productKey={product.productKey}
          brandKey={product.brand.key}
          description={product.description}
          isMarketplace={product.isMarketplace}
          marketplaceOfferId={product.marketplaceOfferId || ''}
          marketplaceSellerId={product.marketplaceSellerId || ''}
          productAttributes={product.attributes}
          displaySellerName={product.displaySellerName || ''}
        />
      )}
    </>
  )
}

export default ResponsiveProduct
