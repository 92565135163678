import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { localStorage } from 'react-storage'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import { Breakpoints } from '@src/utils/useResponsiveSizes'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { TableCell, TableRow } from '@material-ui/core'
import { SubstanceProductFieldsFragment } from '@src/queries/ProductSearchQuery.generated'
import HandleMarkup from '@src/components/HandleMarkup'
import MapAttributeValues from '@src/components/MapAttributeValues'
import ProductFeaturesBadges from '@src/components/ProductFeaturesBadges'
import ProductPriceAvailability from '@src/components/ProductPriceAvailability'
import ProductPaMessage, {
  ProductPaMessageDisplayType,
} from '@src/components/ProductPriceAvailability/ProductPaMessage'
import { AemAdditionalColumns } from '@src/aem-content/components/DynamicProductTable'
import {
  determinePAForAddtoCart,
  sendProductClickEvent,
} from '@src/utils/analytics'
import { EventValues } from '@sial/common-utils'
import ShowMoreLessButton from '@src/components/ShowMoreLessButton'
import ProductLink, { ProductLinkType } from '@src/components/ProductLink'
import { sendPricingAndAvailabilityEvent } from '@src/utils/analytics/pricingAndAvailability'
import { useRouter } from '@src/routes'
import ViewPricingLink from '@src/components/ProductPricingModal/ViewPricingLink'
import { useCurrentUser } from '@src/utils/useCurrentUser'

const useStyles = makeStyles((theme: Theme) => {
  return {
    tableRow: {
      height: '2.5rem',
      '& .MuiTableCell-body': {
        padding: '.2rem .6rem',
      },
    },
    aemMobileResponsiveCell: {
      display: 'block',
      height: 'unset',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      padding: theme.spacing(4, 5),
      margin: theme.spacing(0, -5),
      '&:first-child': {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
      },
      '&:nth-child(2n)': {
        backgroundColor: theme.palette.background.grey,
      },
      '& > td': {
        display: 'block',
        textAlign: 'left',
        border: '0 none',
        padding: '0 !important',
        marginTop: theme.spacing(2),
        minWidth: '100%',
        '&:first-child': {
          marginTop: 0,
          '& > a': {
            fontWeight: theme.typography.fontWeightRegular,
          },
        },
        '&:nth-child(3)': {
          '& > a': {
            fontSize: theme.typography.pxToRem(14),
          },
        },
        '&:last-child': {
          marginTop: theme.spacing(3),
        },
        '& > a': {
          fontSize: theme.typography.pxToRem(16),
        },
      },
    },
    productImageContainer: {
      height: '3.2rem',
      width: '3.2rem',
      display: 'flex',
      alignItems: 'center',
    },
    productImage: {
      height: 'auto',
      width: '100%',
    },
    productName: {
      paddingTop: theme.spacing(1),
    },
    productNumberContainer: {
      wordBreak: 'break-word',
      overflowWrap: 'break-word',
      paddingRight: '0 !important',
      fontWeight: theme.typography.fontWeightMedium,
    },
    aemproductNumberDescCell: {
      '& a': {
        color: 'inherit',
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover': {
          color: theme.palette.primary.main,
          '& > *': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    productDescription: {
      maxWidth: '18rem',
    },
    productDescLink: {
      wordBreak: 'break-word',
      color: theme.palette.common.black,
      fontWeight: 'normal',
    },
    hideOnMobile: {
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    clearBottomBorder: {
      '& > *': {
        borderBottomColor: 'transparent',
      },
    },
    tableRowExpanded: {
      backgroundColor: theme.palette.background.grey,
      borderBottom: 0,
      '& > td:last-child': {
        marginTop: 0,
      },
    },
    aemMobileResponsiveCellExpended: {
      display: 'block',
      margin: theme.spacing(0, -5),
      padding: theme.spacing(0, 5, 5),
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      '& > td': {
        display: 'block',
        padding: 0,
        border: '0 none',
        '& [class*="div-root"]': {
          marginBottom: 0,
        },
        '& [class*="makeStyles-body"] [class*="div-root"]': {
          marginBottom: theme.typography.pxToRem(16),
        },
        '& h3': {
          margin: theme.spacing(0, 0, 4),
          fontSize: theme.typography.pxToRem(16),
        },
      },
    },
    showLessToggleWrap: {
      [theme.breakpoints.down('xs')]: {
        '& > button': {
          marginTop: theme.typography.pxToRem(18),
        },
      },
    },

    // Prevents the table's columns from changing widths when the expand/hide button
    // changes its text. Allows the column to take the minimum amount of space needed
    // no matter what the translated strings end up being.
    expandHideSpacer: {
      height: 0,
      visibility: 'hidden',
    },
  }
})

const attributeValues = {
  CLONALITY: { key: 'clonality-clone_number.default' },
  SPECIES_REACTIVITY: { key: 'species reactivity.reactivity' },
  PORE_SIZE: { key: 'pore size.default' },
  DIAMETER: { key: 'composite dimension.default', label: 'diam.' },
  STERILITY: { key: 'sterilization.default' },
  MATERIAL: { key: 'material.default' },
}

interface ProductProps {
  product: SubstanceProductFieldsFragment
  displayBadgesOnProduct: boolean
  focus: string
  isShowBrand?: boolean // aem props
  isShowImage?: boolean // aem prop
  additionalColumns?: AemAdditionalColumns[] // aem prop
  productIsAntibody: boolean
  productIsSyringeFilter: boolean
  displayViewLink?: boolean
  substanceId?: string
  substancePosition?: number
}

const Product: React.FC<ProductProps> = ({
  product,
  displayBadgesOnProduct,
  focus,
  isShowBrand,
  isShowImage,
  additionalColumns,
  productIsAntibody,
  productIsSyringeFilter,
  displayViewLink = true,
  substanceId,
  substancePosition,
}) => {
  const router = useRouter()
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const [pricingExpanded, setPricingExpanded] = useState(false)
  const { currentUser } = useCurrentUser()

  const querySearchTerm: string = localStorage?.getItem('searchedfor') ?? ''

  const handlePricingClick = () => {
    setPricingExpanded((curr) => !curr)
    sendPricingAndAvailabilityEvent(
      pricingExpanded ? EventValues.ClosePandA : EventValues.ExpandPandA,
      product.productNumber,
      {
        clickType: 'list',
        clickAction: 'View P&A',
        productId: product.productNumber,
        productName: product.name,
        productBrand: product.brand.key,
        component: 'list',
        elementType: 'accordion',
        linkText: pricingExpanded ? 'hide' : 'expand',
      }
    )
  }

  let clonality
  let speciesReactivity
  let poreSize
  let diameter
  let sterility
  let materials
  const attributes = product.attributes

  if (productIsAntibody) {
    clonality = attributes.find(
      (att) => att.key === attributeValues.CLONALITY.key
    )
    speciesReactivity = attributes.find(
      (attr) => attr.key === attributeValues.SPECIES_REACTIVITY.key
    )
  } else if (productIsSyringeFilter) {
    poreSize = attributes.find(
      (attr) => attr.key === attributeValues.PORE_SIZE.key
    )
    diameter = attributes.find(
      (attr) =>
        attr.key === attributeValues.DIAMETER.key &&
        attr.label === attributeValues.DIAMETER.label
    )
    sterility = attributes.find(
      (attr) => attr.key === attributeValues.STERILITY.key
    )
    materials = attributes.filter(
      (attr) => attr.key === attributeValues.MATERIAL.key
    )
  }
  const { paMessage } = product
  const productNumberLabel = product.productNumber.startsWith('CB_')
    ? formatMessage({ id: 'DETAILS', defaultMessage: 'Details' })
    : product.productNumber

  // Display the link to view pricing for the new SRP P&A view if the isNewPNASrpEnabled feature flag is set
  // for the current country, and if debugging mode is not active (debug query parameter not present).
  // Users can revert to the old SRP view by adding '&debug=true' to the query parameters.

  const showViewPricingLink =
    currentUser?.metadata.isNewPNASrpEnabled &&
    !router.query.debug &&
    displayViewLink

  const handleProductClick = () =>
    sendProductClickEvent({
      product,
      searchTerm: querySearchTerm,
      substanceId,
      substancePosition,
    })
  const theme = useTheme()
  const isMdOrUp = useMediaQuery(theme.breakpoints.up(Breakpoints.MD), {
    defaultMatches: true,
  })
  return (
    <>
      <TableRow
        className={
          pricingExpanded
            ? clsx(
                classes.tableRow,
                classes.clearBottomBorder,
                classes.tableRowExpanded,
                !isMdOrUp ? classes.aemMobileResponsiveCell : ''
              )
            : clsx(
                classes.tableRow,
                !isMdOrUp ? classes.aemMobileResponsiveCell : ''
              )
        }
      >
        {isShowImage ? (
          <>
            <TableCell>
              <span className={classes.productImageContainer}>
                <img
                  className={classes.productImage}
                  src={product?.images[0]?.smallUrl}
                  alt={product?.images[0]?.altText || ''}
                />
              </span>
            </TableCell>
            <TableCell classes={{ root: classes.productNumberContainer }}>
              <ProductLink
                product={product}
                focus={focus}
                label={productNumberLabel}
                productDescriptionClass={classes.productName}
                productLinkType={ProductLinkType.NAME}
                productDescription={product.description}
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              classes={{
                root:
                  classes.productNumberContainer +
                  ' ' +
                  classes.aemproductNumberDescCell,
              }}
            >
              <ProductLink
                product={product}
                focus={focus}
                label={productNumberLabel}
                onProductLinkClick={handleProductClick}
                productDescriptionClass={classes.productName}
                productLinkType={ProductLinkType.NAME}
                productDescription={product.description}
              />
            </TableCell>
          </>
        )}
        {productIsAntibody && product.cardAttribute && (
          <>
            <TableCell>
              {clonality && <MapAttributeValues values={clonality.values} />}
            </TableCell>
            <TableCell>
              <HandleMarkup
                value={product.cardAttribute.application.join(', ')}
              />
            </TableCell>
            <TableCell>
              {speciesReactivity && (
                <MapAttributeValues values={speciesReactivity.values} />
              )}
            </TableCell>
            <TableCell>{product.cardAttribute.citationCount}</TableCell>
            <TableCell>
              {displayBadgesOnProduct && (
                <ProductFeaturesBadges features={product.features} />
              )}
            </TableCell>
          </>
        )}
        {productIsSyringeFilter && (
          <>
            <TableCell data-testid="pdp-product-poreSize">
              {poreSize && poreSize.values.length && (
                <MapAttributeValues values={poreSize.values} />
              )}
            </TableCell>
            <TableCell data-testid="pdp-product-diameter">
              {diameter && <MapAttributeValues values={diameter.values} />}
            </TableCell>
            <TableCell data-testid="pdp-product-sterility">
              {sterility && <MapAttributeValues values={sterility.values} />}
            </TableCell>
            <TableCell data-testid="pdp-product-material">
              {materials &&
                materials.map((material) => (
                  <MapAttributeValues
                    key={material.key}
                    values={material.values}
                  />
                ))}
            </TableCell>
            <TableCell>
              {displayBadgesOnProduct && (
                <ProductFeaturesBadges features={product.features} />
              )}
            </TableCell>
          </>
        )}
        {!product.cardCategory && (
          <>
            <TableCell className={classes.productDescription} align="left">
              {product.name && (
                <ProductLink
                  product={product}
                  focus={focus}
                  label={product.name}
                  productDescriptionClass={''}
                  productLinkType={ProductLinkType.DESCRIPTION}
                  productDescription={product.name}
                />
              )}
            </TableCell>

            <TableCell
              className={
                classes.productDescription +
                ' ' +
                classes.aemproductNumberDescCell
              }
              align="left"
            >
              <ProductLink
                product={product}
                focus={focus}
                label={product.description}
                productDescriptionClass={classes.productDescLink}
                productLinkType={ProductLinkType.DESCRIPTION}
                productDescription={product.description}
              />
            </TableCell>
            {isShowBrand && (
              <TableCell
                className={classes.hideOnMobile}
                style={{ color: product.brand.color || undefined }}
              >
                {product.brand.name}
              </TableCell>
            )}
            {additionalColumns &&
              additionalColumns.map(({ value }) => {
                const columnAttr = product.attributes.find(
                  (item) =>
                    item.key && item.key.toLowerCase() === value.toLowerCase()
                )
                return (
                  <TableCell key={value}>
                    <HandleMarkup value={columnAttr?.values?.join(', ')} />
                  </TableCell>
                )
              })}
          </>
        )}
        <TableCell align="right">
          {showViewPricingLink ? (
            <ViewPricingLink product={product} focus={focus} />
          ) : (
            <>
              {!isMdOrUp ? (
                !pricingExpanded && (
                  <ShowMoreLessButton
                    expanded={pricingExpanded}
                    setExpanded={handlePricingClick}
                    collapsedText={formatMessage({
                      id: 'EXPAND',
                      defaultMessage: 'Expand',
                    })}
                    expandedText={formatMessage({
                      id: 'HIDE',
                      defaultMessage: 'Hide',
                    })}
                    data-testid={`srp-pricing-${product.productNumber}${product.brand.key}`}
                  />
                )
              ) : (
                <ShowMoreLessButton
                  expanded={pricingExpanded}
                  setExpanded={handlePricingClick}
                  collapsedText={formatMessage({
                    id: 'EXPAND',
                    defaultMessage: 'Expand',
                  })}
                  expandedText={formatMessage({
                    id: 'HIDE',
                    defaultMessage: 'Hide',
                  })}
                  data-testid={`srp-pricing-${product.productNumber}${product.brand.key}`}
                />
              )}
              <div className={classes.expandHideSpacer}>
                <ShowMoreLessButton
                  expanded={!pricingExpanded}
                  setExpanded={handlePricingClick}
                  collapsedText={formatMessage({
                    id: 'EXPAND',
                    defaultMessage: 'Expand',
                  })}
                  expandedText={formatMessage({
                    id: 'HIDE',
                    defaultMessage: 'Hide',
                  })}
                />
              </div>
            </>
          )}
        </TableCell>
      </TableRow>
      {pricingExpanded && (
        <TableRow
          className={
            classes.tableRowExpanded +
            ' ' +
            (!isMdOrUp ? classes.aemMobileResponsiveCellExpended : '')
          }
        >
          <TableCell colSpan={9}>
            {paMessage ? (
              <ProductPaMessage
                brandKey={product.brand.key}
                productNumber={product.productNumber}
                paMessage={paMessage}
                displayType={ProductPaMessageDisplayType.SRP}
              />
            ) : (
              <ProductPriceAvailability
                queryFocus={focus}
                productName={product.name}
                productNumber={product.productNumber}
                productDescription={product.description || ''}
                images={product.images}
                materialIds={product.materialIds}
                brandKey={product.brand.key}
                gaType={determinePAForAddtoCart(focus)}
                isMarketplace={product.isMarketplace}
                productAttributes={product.attributes}
                marketplaceSellerId={product.marketplaceSellerId || ''}
                marketplaceOfferId={product.marketplaceOfferId || ''}
                displaySellerName={product.displaySellerName || ''}
                displayPromotionalBundlesAnchor
                checkForPb
                productKey={product.productKey}
                erp_type={product.erp_type}
              />
            )}
            {!isMdOrUp && (
              <div className={classes.showLessToggleWrap}>
                <ShowMoreLessButton
                  expanded={pricingExpanded}
                  setExpanded={handlePricingClick}
                  collapsedText={formatMessage({
                    id: 'EXPAND',
                    defaultMessage: 'Expand',
                  })}
                  expandedText={formatMessage({
                    id: 'HIDE',
                    defaultMessage: 'Hide',
                  })}
                  data-testid={`srp-pricing-${product.productNumber}${product.brand.key}`}
                />
              </div>
            )}
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default Product
