import React, { FC } from 'react'
import {
  MappedComponentProperties,
  MapTo,
} from '@adobe/aem-react-editable-components'
import { Chat, ChatChannel } from '@src/components/Chat'
import { FormattedMessage, useIntl } from 'react-intl'
import { useRouter } from '@src/routes'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { Typography, useMediaQuery } from '@material-ui/core'
import { Breakpoints } from '@src/utils/useResponsiveSizes'
import Box from '@src/components/Box'
import ShoppingAiBanner from '@src/routes/ShoppingAi/ShoppingAiBanner'
import getConfig from 'next/config'

const useStyles = makeStyles((theme: Theme) => {
  return {
    lookingForMore: {
      fontWeight: theme.typography.fontWeightBold,
    },
    alertMessage: {
      margin: '0 !important',
    },
  }
})

const {
  publicRuntimeConfig: { featureFlags },
} = getConfig()

interface GenericPlaceholderProps extends MappedComponentProperties {
  placeholder?: string
}

const GenericPlaceholderAdapter: FC<GenericPlaceholderProps> = ({
  placeholder,
}) => {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const router = useRouter()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(Breakpoints.MD), {
    defaultMatches: true,
  })

  const isShowChat =
    placeholder === 'live_chat' &&
    (router.query.country === 'AR' || router.query.country === 'CL')

  const isShowShoppingAssistant =
    placeholder === 'shopping_assistant' && featureFlags.shoppingAi && !isMobile

  return (
    <>
      {isShowChat && (
        <Chat
          text={formatMessage({
            id: 'COMMERCE_CHAT_CUSTOMER_CARE',
            defaultMessage: 'Chat with Customer Care',
          })}
          channel={ChatChannel.CustomerService}
          isAemPageChat
        />
      )}
      {isShowShoppingAssistant && (
        <Box mb={6}>
          <ShoppingAiBanner
            alertMessage={
              <Typography className={classes.alertMessage}>
                <span className={classes.lookingForMore}>
                  <FormattedMessage
                    id="LOOKING_FOR_MORE_GUIDANCE"
                    defaultMessage="Looking for more guidance?"
                  />{' '}
                </span>
                <FormattedMessage
                  id="LET_LIFE_SCIENCE_AI_ASSISTANT_HELP_PERFECT_MATCH"
                  defaultMessage="Let our Life Science AI Assistant help you find your perfect match with confidence."
                />
              </Typography>
            }
            gaSection={'faqs'}
          />
        </Box>
      )}
    </>
  )
}

export default MapTo('cms-commons/components/content/genericPlaceholder')(
  GenericPlaceholderAdapter
)
