import React from 'react'
import { useIntl } from 'react-intl'
import { MapTo } from '@adobe/aem-react-editable-components'
import PressReleaseListing from '@sial/aem-cms-components/dist/PressReleaseListing/PressReleaseListing'

const PressReleaseListingAdapter: React.FC<any> = (props) => {
  const intl = useIntl()
  const noSearchResults = intl.formatMessage({
    id: 'NO_SEARCH_RESULTS',
    defaultMessage: 'No search results',
  })
  const changeFilterCriteria = intl.formatMessage({
    id: 'CHANGE_FILTER_CRITERIA',
    defaultMessage: 'Try changing or broadening your filter criteria.',
  })
  const dateRangeStart = intl.formatMessage({
    id: 'DATE_RANGE_START',
    defaultMessage: 'Date Range Start',
  })
  const dateRangeEnd = intl.formatMessage({
    id: 'DATE_RANGE_END',
    defaultMessage: 'Date Range End',
  })
  const uncheckAll = intl.formatMessage({
    id: 'FACET_UNCHECK_ALL',
    defaultMessage: 'Uncheck All',
  })

  const facetTopic = intl.formatMessage({
    id: 'FACET_TOPIC',
    defaultMessage: 'Topic',
  })

  const appliedFilters = intl.formatMessage({
    id: 'APPLIED_FILTERS_HEADING',
    defaultMessage: 'Applied Filters:',
  })
  const selectDate = intl.formatMessage({
    id: 'SELECT_DATE',
    defaultMessage: 'Select Date',
  })

  const done = intl.formatMessage({ id: 'DONE', defaultMessage: 'Done' })
  const filters = intl.formatMessage({
    id: 'FILTERS',
    defaultMessage: 'Filters',
  })

  const invalidDate = intl.formatMessage({
    id: 'ENTER_DATE_INVALID',
    defaultMessage: 'Enter valid date',
  })
  const invalidStartDate = intl.formatMessage({
    id: 'START_DATE_INVALID',
    defaultMessage: 'Start Date should be before End Date',
  })
  const invalidEndDate = intl.formatMessage({
    id: 'END_DATE_INVALID',
    defaultMessage: 'End Date should be after Start Date',
  })

  const translations = {
    appliedFilters,
    changeFilterCriteria,
    dateRangeEnd,
    dateRangeStart,
    done,
    facetTopic,
    filters,
    invalidDate,
    invalidEndDate,
    invalidStartDate,
    noSearchResults,
    selectDate,
    uncheckAll,
  }

  return <PressReleaseListing {...props} translations={translations} />
}

export default MapTo('cms-commons/components/content/pressreleaselisting')(
  PressReleaseListingAdapter
)
