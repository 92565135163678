// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import { SubstanceProductFieldsFragmentDoc } from './ProductSearchQuery.generated';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type MultipleProductDetailSubstanceProductQueryVariables = Types.Exact<{
  productInputs: Array<Types.ProductInput> | Types.ProductInput;
}>;


export type MultipleProductDetailSubstanceProductQuery = { __typename?: 'Query', getMultipleProductDetails: Array<{ __typename?: 'Product', name: string, displaySellerName?: string | null, productNumber: string, productKey: string, isSial: boolean, isMarketplace: boolean, marketplaceSellerId?: string | null, marketplaceOfferId?: string | null, cardCategory?: string | null, speciesReactivity: Array<string>, description?: string | null, sdsLanguages: Array<string | null>, sdsPnoKey: string, similarity?: string | null, paMessage?: string | null, features: Array<string>, catalogId?: Types.CatalogType | null, materialIds: Array<string>, erp_type?: Array<string> | null, legalNameSuffix?: string | null, isBBE?: boolean | null, cardAttribute?: { __typename?: 'CardAttribute', citationCount?: string | null, application: Array<string> } | null, attributes: Array<{ __typename?: 'ProductAttribute', key: string, label: string, values: Array<string> }>, brand: { __typename?: 'Brand', key: string, erpKey: string, name?: string | null, color?: string | null }, images: Array<{ __typename?: 'CatalogImage', altText?: string | null, smallUrl: string, mediumUrl: string, largeUrl: string }>, keyAttributes?: Array<{ __typename?: 'ProductAttribute', key: string, label: string, values: Array<string> }> | null } | null> };


export const MultipleProductDetailSubstanceProductDocument = gql`
    query MultipleProductDetailSubstanceProduct($productInputs: [ProductInput!]!) {
  getMultipleProductDetails(input: {productInputs: $productInputs}) {
    ...SubstanceProductFields
  }
}
    ${SubstanceProductFieldsFragmentDoc}`;
export type MultipleProductDetailSubstanceProductComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MultipleProductDetailSubstanceProductQuery, MultipleProductDetailSubstanceProductQueryVariables>, 'query'> & ({ variables: MultipleProductDetailSubstanceProductQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MultipleProductDetailSubstanceProductComponent = (props: MultipleProductDetailSubstanceProductComponentProps) => (
      <ApolloReactComponents.Query<MultipleProductDetailSubstanceProductQuery, MultipleProductDetailSubstanceProductQueryVariables> query={MultipleProductDetailSubstanceProductDocument} {...props} />
    );
    

/**
 * __useMultipleProductDetailSubstanceProductQuery__
 *
 * To run a query within a React component, call `useMultipleProductDetailSubstanceProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultipleProductDetailSubstanceProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultipleProductDetailSubstanceProductQuery({
 *   variables: {
 *      productInputs: // value for 'productInputs'
 *   },
 * });
 */
export function useMultipleProductDetailSubstanceProductQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MultipleProductDetailSubstanceProductQuery, MultipleProductDetailSubstanceProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MultipleProductDetailSubstanceProductQuery, MultipleProductDetailSubstanceProductQueryVariables>(MultipleProductDetailSubstanceProductDocument, options);
      }
export function useMultipleProductDetailSubstanceProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MultipleProductDetailSubstanceProductQuery, MultipleProductDetailSubstanceProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MultipleProductDetailSubstanceProductQuery, MultipleProductDetailSubstanceProductQueryVariables>(MultipleProductDetailSubstanceProductDocument, options);
        }
export type MultipleProductDetailSubstanceProductQueryHookResult = ReturnType<typeof useMultipleProductDetailSubstanceProductQuery>;
export type MultipleProductDetailSubstanceProductLazyQueryHookResult = ReturnType<typeof useMultipleProductDetailSubstanceProductLazyQuery>;
export type MultipleProductDetailSubstanceProductQueryResult = ApolloReactCommon.QueryResult<MultipleProductDetailSubstanceProductQuery, MultipleProductDetailSubstanceProductQueryVariables>;