// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type GetCountryDetailsQueryVariables = Types.Exact<{
  country: Types.Scalars['String'];
}>;


export type GetCountryDetailsQuery = { __typename?: 'Query', getCountryDetails?: { __typename?: 'CountryAccountSetup', guestShoppingType?: string | null, salesOfficeCode?: string | null, salesOrg?: string | null } | null };


export const GetCountryDetailsDocument = gql`
    query getCountryDetails($country: String!) {
  getCountryDetails(country: $country) {
    guestShoppingType
    salesOfficeCode
    salesOrg
  }
}
    `;
export type GetCountryDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCountryDetailsQuery, GetCountryDetailsQueryVariables>, 'query'> & ({ variables: GetCountryDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCountryDetailsComponent = (props: GetCountryDetailsComponentProps) => (
      <ApolloReactComponents.Query<GetCountryDetailsQuery, GetCountryDetailsQueryVariables> query={GetCountryDetailsDocument} {...props} />
    );
    

/**
 * __useGetCountryDetailsQuery__
 *
 * To run a query within a React component, call `useGetCountryDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryDetailsQuery({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useGetCountryDetailsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCountryDetailsQuery, GetCountryDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCountryDetailsQuery, GetCountryDetailsQueryVariables>(GetCountryDetailsDocument, options);
      }
export function useGetCountryDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCountryDetailsQuery, GetCountryDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCountryDetailsQuery, GetCountryDetailsQueryVariables>(GetCountryDetailsDocument, options);
        }
export type GetCountryDetailsQueryHookResult = ReturnType<typeof useGetCountryDetailsQuery>;
export type GetCountryDetailsLazyQueryHookResult = ReturnType<typeof useGetCountryDetailsLazyQuery>;
export type GetCountryDetailsQueryResult = ApolloReactCommon.QueryResult<GetCountryDetailsQuery, GetCountryDetailsQueryVariables>;