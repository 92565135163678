import React from 'react'
import { Button } from '@material-ui/core'
import LiquidInputAdapter from '@src/components/LiquidInputAdapter'
import messages from '@src/utils/messages'
import { useUserSession } from '@src/utils/useUserSession'
import { sendRequestTrialLicenseEvent } from '@src/utils/analytics'
import { useCurrentUser } from '@utils/useCurrentUser'
import { Field, Form, Formik, getIn } from 'formik'
import { FormattedMessage } from 'react-intl'
import { useApolloClient } from '@apollo/react-hooks'
import { TRIAL_SOFTWARE_LICENSE } from '@src/queries/TrialSoftwareLicenseQuery'
import clsx from 'clsx'
import { useTheme } from '@material-ui/core/styles'

export interface RequestLicenseSectionProps {
  classes: any
  validationSchema: any
  intl: any
  setQueryResult: any
}

export const RequestLicenseSection: React.FC<RequestLicenseSectionProps> = ({
  classes,
  validationSchema,
  intl,
  setQueryResult,
}) => {
  const theme = useTheme()
  const userSession = useUserSession()
  const client = useApolloClient()
  const { userId = '' } = useCurrentUser()
  const rootStyle = {
    color: theme.palette.text.primary,
    fontWeight: '400',
  }
  const helpText = {
    marginTop: 0,
    height: 0,
  }
  const handleClick = (form) => {
    const serialNumber = getIn(form.values, `serialNumber`)
    const hasError = getIn(form.errors, `serialNumber`)
    if (serialNumber?.length === 10 && hasError)
      sendRequestTrialLicenseEvent(
        userId,
        messages.DIGITAL_PRODUCT_SERIAL_NUMBER_INVALID
      )
  }
  return (
    <div className={classes.subSection}>
      <Formik
        initialValues={{
          serialNumber: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true)

          const result = await client.query({
            query: TRIAL_SOFTWARE_LICENSE,
            context: { userSession },
            variables: {
              serialNumber: values.serialNumber,
            },
            errorPolicy: 'all',
            fetchPolicy: 'no-cache',
          })
          setQueryResult(result)
          if (result.errors) {
            let message
            result.errors.forEach((error) => {
              if (
                error?.extensions?.ecommerceErrors[0].errorCode ===
                'SERIAL_NUMBER_ALREADY_HAS_LICENSE_KEY'
              ) {
                sendRequestTrialLicenseEvent(
                  userId,
                  messages.DIGITAL_PRODUCT_KEY_ALREADY_GENERATED
                )
                message = intl.formatMessage(
                  messages.DIGITAL_PRODUCT_KEY_ALREADY_GENERATED
                )
              } else {
                message = intl.formatMessage(messages.GENERIC_ERROR)
              }
            })

            actions.setErrors({ serialNumber: message })
          } else {
            sendRequestTrialLicenseEvent(userId)
          }
          actions.setSubmitting(false)
        }}
      >
        {(formikBag) => (
          <Form className={classes.form}>
            <Field
              name="serialNumber"
              className={classes.input}
              component={LiquidInputAdapter}
              placeholder={intl.formatMessage(messages.SERIAL_NUMBER)}
              label={
                <FormattedMessage {...messages.DIGITAL_PRODUCT_SERIAL_NUMBER} />
              }
              filled
              style={rootStyle}
              helpStyle={helpText}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              data-testid="request-license-button"
              id="request-license-button"
              className={clsx(classes.formButton, classes.requestLicenseButton)}
              onClick={() => handleClick(formikBag)}
            >
              <FormattedMessage {...messages.REQUEST_LICENSE} />
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default RequestLicenseSection
